export const stackRouterComponents = {
    UserDetailPage: () => import('@/routes/user-detail/UserDetailPage'),
    UserBlindProfilePage: () => import('@/routes/user-detail/UserBlindProfilePage'),
    SearchKeywordsPage: () => import('@/routes/my-page/SearchKeywordsPage'),
    ReportSelectReasonPage: () => import('@/routes/report/ReportSelectReasonPage'),
    ReportSubmitPage: () => import('@/routes/report/ReportSubmitPage'),
    DatingStatusCheckPage: () => import('@/routes/user-feedback/DatingStatusCheckPage'),
    DatingFeedbackPage: () => import('@/routes/user-feedback/DatingFeedbackPage'),
    CommunicationFeedbackPage: () => import('@/routes/user-feedback/CommunicationFeedbackPage'),
    RecommendablePersonPage: () => import('@/routes/user-feedback/RecommendablePersonPage'),
    RecommendableReasonPage: () => import('@/routes/user-feedback/RecommendableReasonPage'),
    UnrecommendableReasonPage: () => import('@/routes/user-feedback/UnrecommendableReasonPage'),
    DatingSincerityPage: () => import('@/routes/user-feedback/DatingSincerityPage'),
    DatingInsincerePage: () => import('@/routes/user-feedback/DatingInsincerePage'),
    DatingMannerPage: () => import('@/routes/user-feedback/DatingMannerPage'),
    DatingNoMannerPage: () => import('@/routes/user-feedback/DatingNoMannerPage'),

    ContactsListPage: () => import('@/routes/invitationFriend/ContactsListPage'),
    SuperPrivacyIntroductionPage: () => import('@/routes/super-privacy-introduction/SuperPrivacyIntroductionPage'),

    // blockPage
    BlockUserPage: () => import('@/routes/block-contacts/BlockUserPage'),
    BlockContactsPage: () => import('@/routes/block-contacts/BlockContactsPage'),
    BlockCompaniesPage: () => import('@/routes/block-contacts/BlockCompaniesPage'),
    BlockCollegesPage: () => import('@/routes/block-contacts/BlockCollegesPage'),
    BlockNamesPage: () => import('@/routes/block-contacts/BlockNamesPage'),
    BlockCollegeSearch: () => import('@/routes/block-contacts/components/BlockCollegeSearch'),

    FriendReferralPage: () => import('@/routes/invitationFriend/FriendReferralPage'),

    // 프로필 페이지 스텍라우터
    EditProfileItemsV2: () => import('@/routes/my-page/EditProfileItemsV2'),
    EditProfilePage: () => import('@/routes/my-page/EditProfilePage'),
    InAppStorePage: () => import('@/routes/my-page/InAppStorePage'),
    EditStylePage: () => import('@/routes/my-page/EditStylePage'),
    FeeInformationPage: () => import('@/routes/my-page/FeeInformationPage'),
    EditDatingPreferencePage: () => import('@/routes/my-page/EditDatingPreferencePage'),
    NoticesPage: () => import('@/routes/home/NoticesPage'),
    RefundHistoriesPage: () => import('@/routes/RefundHistoriesPage'), //
    FAQPage: () => import('@/routes/faq/FAQPage'), //
    SettingsPage: () => import('@/routes/setting/SettingsPage'),
    ChangePasswordPage: () => import('@/routes/setting/ChangePasswordPage'),
    DormantPage: () => import('@/routes/setting/DormantPage'),
    NotificationDisplayPage: () => import('@/routes/setting/NotificationDisplayPage'),
    EnterCodePage: () => import('@/routes/invitationFriend/EnterCodePage'),
    CertificatePage: () => import('@/routes/my-page/CertificatePage'),
    CertificateDetailPage: () => import('@/routes/my-page/CertificateDetailPage'),
    CertificateCompaniesPage: () => import('@/routes/my-page/CertificateCompaniesPage'),
    CertificateCollegesPage: () => import('@/routes/my-page/CertificateCollegesPage'),
    CertificateIncomesPage: () => import('@/routes/my-page/CertificateIncomesPage'),
    CertificateEmployeeIncomePage: () => import('@/routes/my-page/CertificateEmployeeIncomePage'),
    CertificateBusinessIncomePage: () => import('@/routes/my-page/CertificateBusinessIncomePage'),

    JobDetail: () => import('@/routes/my-page/edit-profile-detail/JobDetail'),
    MakeContractPage: () => import('@/routes/my-page/MakeContractPage'),
    MakeTruthContractPage: () => import('@/routes/my-page/MakeTruthContractPage'),

    // edit profile detail
    EditProfileDetailFinance: () => import('@/routes/my-page/edit-profile-detail/EditProfileDetailFinance'),
    EditProfileDetailEducation: () => import('@/routes/my-page/edit-profile-detail/EditProfileDetailEducation'),
    EditProfileDetailHouse: () => import('@/routes/my-page/edit-profile-detail/EditProfileDetailHouse'),
    EditProfileDetailFamily: () => import('@/routes/my-page/edit-profile-detail/EditProfileDetailFamily'),
    EditProfileDetailFamilyRelations: () =>
        import('@/routes/my-page/edit-profile-detail/EditProfileDetailFamilyRelations'),
    EditProfileDetailParentStatus: () => import('@/routes/my-page/edit-profile-detail/EditProfileDetailParentStatus'),
    EditProfileText: () => import('@/routes/my-page/edit-profile-detail/EditProfileText'),
    EditProfileDetailHealth: () => import('@/routes/my-page/edit-profile-detail/EditProfileDetailHealth'),
    EditProfileDetailJobFree: () => import('@/routes/my-page/edit-profile-detail/EditProfileDetailJobFree'),

    // 약속잡기
    PromisePage: () => import('@/routes/promise/PromisePage'),
    SchedulePayPage: () => import('@/routes/promise/SchedulePayPage'),
    MeetingFeedback: () => import('@/routes/promise/MeetingFeedback'),
    RescheduleMessagePage: () => import('@/routes/promise/RescheduleMessagePage'),
    TicketPaymentMethodsPage: () => import('@/routes/prepay/TicketPaymentMethodsPage'),
    PrePaymentPage: () => import('@/routes/prepay/PrePaymentPage'),
    MembershipDetailPage: () => import('@/routes/membership/MembershipDetailPage'),
    ChangeScheduleDetailPage: () => import('@/routes/schedule/ChangeScheduleDetailPage'),
    ScheduleCancelPage: () => import('@/routes/ScheduleCancelPage'),

    WaiveRequestPage: () => import('@/routes/schedule/WaiveRequestPage'),
    WaiveSubmitPage: () => import('@/routes/schedule/WaiveSubmitPage'),

    // 온보딩 프로필 입력
    ProfileSteps: () => import('@/routes/chat/chats/components/ProfileSteps'),
    NeedGradeTest: () => import('@/routes/NeedGradeTest'),

    StorySelector: () => import('@/routes/my-page/components/profile-sections/StorySelector'),

    MembershipIntroductionPage: () => import('@/routes/membership/MembershipIntroductionPage'),
    ManagerIntroductionPage: () => import('@/routes/membership/ManagerIntroductionPage'),

    OnBoardingPage: () => import('@/routes/onboarding/OnBoardingPage'),
}

export function isStackRoute(name) {
    if (!name) return false

    return Object.keys(stackRouterComponents).findIndex(key => key === name) > -1
}
