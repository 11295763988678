<template>
    <div class="product-wrapper">
        <div class="membership-detail" v-if="selectedMembership">
            <!-- <swiper :options="swiperOptions" ref="mySwiper"> -->
            <div v-for="idx in idxList" :key="idx">
                <component :is="`MembershipDetail${idx}`" :selectedMembership="selectedMembership" />
            </div>
            <!-- </swiper> -->
        </div>
        <div class="product-page" v-else-if="plans">
            <!-- <ChannelTalk :textStyle="{ color: 'black' }" :default-launcher="true" /> -->
            <div class="introduce-product">
                <h3 class="m-b-16">안심하고 구매하세요</h3>
                <div class="introduce-card" v-for="item in introduceProducts" :key="item.id">
                    <div class="title f-16" v-html="item.title" />
                    <div class="content f-12 m-t-8" v-html="item.content" />
                </div>
            </div>
            <div class="items">
                <h3 class="m-t-40 m-b-16">남성 회원 상품</h3>
                <ProductItem v-for="item in forMale" :key="item.id" :category-item="item" :gender="'male'" />
                <div class="tax-description m-t-9 f-12">*모든 상품은 부가세 별도입니다.</div>
            </div>
            <div class="items">
                <h3 class="m-t-40 m-b-16">여성 회원 상품</h3>
                <ProductItem v-for="item in forFemale" :key="item.id" :category-item="item" :gender="'female'" />
                <div class="tax-description m-t-9 f-12">*모든 상품은 부가세 별도입니다.</div>
            </div>
            <div class="blank"></div>
        </div>
        <div class="subscribe-button">
            <button class="subscribe-btn inquiry f-18" @click="onClickPurchase('inquiry')">문의하기</button>
            <button class="subscribe-btn f-18" @click="onClickPurchase('purchase')">결제하기</button>
        </div>
    </div>
</template>

<script>
// import ChannelTalk from '@/components/app/ChannelTalk'
import ProductItem from '@/routes/prepay/components/ProductItem'
import inquiryService from '@/services/inquiry'
export default {
    name: 'MembershipDetailPage',
    components: {
        ProductItem,
        MembershipDetail1: () => import('./components/MembershipDetail1'),
        MembershipDetail2: () => import('./components/MembershipDetail2'),
        MembershipDetail3: () => import('./components/MembershipDetail3'),
        MembershipDetail4: () => import('./components/MembershipDetail4'),
        MembershipDetail5: () => import('./components/MembershipDetail5'),
        MembershipDetail6: () => import('./components/MembershipDetail6'),
        MembershipDetail7: () => import('./components/MembershipDetail7'),
        MembershipDetail8: () => import('./components/MembershipDetail8'),
        MembershipDetail9: () => import('./components/MembershipDetail9'),
        MembershipDetail11: () => import('./components/MembershipDetail11'),
    },
    data: () => ({
        selected: null,
        introduceProducts: [
            {
                id: 1,
                title: '만남 100% 보장',
                content: `마음에 드는 분이 없거나, <br/>매칭이 되지 않을 경우 100% 전액 환불 해드립니다.`,
            },
            // {
            //     id: 2,
            //     name: "주도권은 남성에게",
            //     content: "남자가 을인 소개팅앱은 그만하세요. '회원님을 만나고 싶어하는 여성 회원분'만 소개해드려요. 만날지 말지 결정만 하세요."
            // },
        ], // props로 이동시키기
    }),
    methods: {
        // async onClickCategory(category) {
        //     const res = await this.$needUpdate('1.0.2')
        //     if (!res) {
        //         this.$store.commit('setSelectedPlan', category)
        //         this.$router.push({
        //             name: 'PrePaymentCategoryPage',
        //             params: { categoryName: category.category, category },
        //         })
        //     }
        // },

        async onClickPurchase(key) {
            let products = []
            if (this.me.gender === 0) {
                products = this.forMale.filter(o => o.code.includes(this.selectedMembership))
            } else {
                products = this.forFemale.filter(o => o.code.includes(this.selectedMembership))
            }

            if (key === 'purchase') {
                console.log(products)
                if (this.me.profile.status === 'confirmed') {
                    this.$store.commit('setSelectedProduct', this.product)
                    this.$store.commit('setSelectedPlan', this.product)
                    window.localStorage.setItem('selectedMembership', this.selectedMembership)

                    this.$modal.custom({
                        component: 'ModalPurchaseMembership',
                        options: {
                            products: products,
                        },
                    })
                    // this.$router.push({ name: 'PaymentMethodsPage', params: { categoryName: this.product } })

                    // this.$emit('close')
                } else {
                    this.$modal.basic({
                        body: '서류인증이 완료된 후 구매할 수 있습니다.',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                }
            } else {
                const payload = {
                    user_id: this.me.id,
                    category: 'membership',
                    funnel: 'enter_inquiry',
                }
                await inquiryService.updateFunnel(payload)

                this.$modal.custom({
                    component: 'ModalInquiryWriter',
                    options: {
                        product: this.selectedMembership,
                    },
                })
            }
        },
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        products() {
            return this.$store.getters.products
        },
        product() {
            return this.products.subscription_plan[0] // 상세페이지의 product는 각각 다를 예정
        },
        selectedMembership() {
            const storedData = window.localStorage.getItem('selectedMembership')
            if (storedData && !this.$route.params.selectedMembership) {
                return storedData
            } else {
                return this.$route.params.selectedMembership
            }
        },
        plans() {
            return this.$store.getters.plans
        },
        programs() {
            console.log('1111', this.products)
            // const prepaidPrograms = this.products.prepaid.concat(
            //     this.products.unlimited.filter(o => o.code !== 'unlimited_male' && o.code !== 'unlimited_female')
            // )
            const subscriptionPrograms = this.products.subscription_plan
            // const ticketPrograms = this.products.ticket_plan
            const packagePrograms = this.products.package_plan

            const programs = [...subscriptionPrograms, ...packagePrograms]
            const program = programs.slice().sort(function (a, b) {
                return a.id < b.id ? 1 : a.id > b.id ? -1 : 0
            })
            return program
        },
        forMale() {
            return this.programs.filter(o => o.target === 'male' || o.target === 'all')
        },
        forFemale() {
            return this.programs.filter(o => o.target === 'female' || o.target === 'all')
        },
        idxList() {
            let n = 0
            if (this.selectedMembership === 'mf_3month' && this.me.gender === 0) {
                n = 8
            } else if (this.selectedMembership === 'mf_3month' && this.me.gender === 1) {
                n = 11
            } else if (this.selectedMembership === 'mfplus_3month') {
                n = 11
            }
            return Array.from({ length: n }, (_, index) => index + 1)
        },
    },
}
</script>

<style scoped lang="scss">
.product-page {
    padding: 20px;

    .introduce-card {
        background-color: #fdfcfa;
        box-shadow: 0px 2px 20px 0px #0000001a;
        border-radius: 4px;
        padding: 14px 12px;

        .title {
            color: #151360;
            font-weight: 700;
        }

        .content {
            color: #7c7c7c;
            line-height: 18px;
        }
    }

    .tax-description {
        font-weight: 400;
        color: #a9a9a9;
    }

    // .purchase-button {
    //     margin-top: 40px;
    //     .purchase-btn {
    //         width: 100%;
    //         background: #151360;
    //         border-radius: 36px;
    //         color: #F7F7F4;
    //         height: 48px;
    //     }
    // }

    .blank {
        height: 100px;
    }
}

.membership-detail {
    margin-bottom: 90px;
}
.subscribe-button {
    //padding: 20px;
    padding-top: 25px;
    padding-bottom: 15px;
    padding-left: 6px;
    padding-right: 6px;
    display: flex;
    justify-content: space-between;
    bottom: 0px;
    width: 100%;
    //margin: 0 3px;
    //padding: 0 4px;
    height: 88px;
    position: absolute;
    background: #fff9ef;
    z-index: 1;
    .subscribe-btn {
        width: 48% !important;
        border: none;
        background: #151360;
        border-radius: 36px;
        color: #f7f7f4;
        &.inquiry {
            background: #e8e4e7;
            color: #151360;
        }
    }
}
::v-deep .membership-img-container {
    width: 100%;
    color: black;

    .img-wrapper {
        @include center;
        img {
            width: 100%;
        }
    }

    .content {
        text-align: center;

        .title {
            font-size: 18px;
            margin-bottom: 8px;
            @include spoqa-f-medium;
        }
        .desc {
            font-size: 14px;
            line-height: 26px;
        }
    }
}
</style>
