var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stack-router-header-bar",class:{ 'bg-black': _vm.mode === 'dark' }},[_c('div',{staticClass:"left-btn"},[(
                _vm.title === '프로필 수정' ||
                _vm.title === '매칭 선호사항' ||
                _vm.title === '약속 변경 사항' ||
                _vm.title === '약속 취소 수수료 안내'
            )?_c('i',{staticClass:"material-icons",on:{"click":_vm.onClickLeftButton}},[_vm._v("chevron_left")]):_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLeftButton),expression:"showLeftButton"}],staticClass:"m-r-2",attrs:{"src":require(`@/assets/images/icons/${_vm.leftButtonIcon}.png`),"width":"24px","height":"24px"},on:{"click":_vm.onClickLeftButton}}),_c('transition',{attrs:{"name":"fade"}},[(
                    _vm.title !== '프로필 수정' &&
                    _vm.title !== '매칭 선호사항' &&
                    _vm.title !== '약속 변경 사항' &&
                    _vm.title !== '약속 취소 수수료 안내'
                )?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTitle),expression:"showTitle"}],staticClass:"title-wrapper"},[_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.$translate(_vm.title))}})]):_vm._e()])],1),(
            _vm.title === '프로필 수정' ||
            _vm.title === '매칭 선호사항' ||
            _vm.title === '약속 변경 사항' ||
            _vm.title === '약속 취소 수수료 안내'
        )?_c('div',{staticClass:"title-edit-profile"},[_c('div',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.$translate(_vm.title))}})]):_vm._e(),_c('div',{staticClass:"right-btn flex-wrap"},[(_vm.title === '프로필 수정')?_c('div',{staticClass:"edit-profile-complete m-r-4",on:{"click":_vm.onClickRightBtn}},[_vm._v("저장")]):(_vm.title === '수수료 상세 안내')?_c('div',{staticClass:"black-space p-10"}):_vm._e(),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRightButton),expression:"showRightButton"}],staticClass:"material-icons",on:{"click":_vm.onClickRightBtn}},[_vm._v(_vm._s(_vm.rightButtonIcon))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }