<template>
    <div class="invitation-friend" @scroll="onScroll">
        <div class="top-section">
            <div class="head">
                <div class="headline">
                    <p>초대코드로 가입하면</p>
                    <p>적립금 70,000원</p>
                </div>
            </div>
            <div class="desc">
                <p>친구가 굿퍼슨에 가입 후 아래의 초대 코드를 입력하면,</p>
                <p>친구와 본인에게 각각 70,000원의 적립금을 지급해드려요.</p>
                <p class="m-t-5 c-silver f-13">
                    초대 코드는 마이페이지의 초대코드 입력 페이지에서 <br />
                    작성하실 수 있어요.
                </p>
            </div>
        </div>
        <div class="bottom-section">
            <img
                class="coin-set"
                id="coin-set"
                :src="require('@/assets/images/invitation-friend/coin_set.png')"
                alt=""
                width="100%"
            />
            <div class="body">
                <div class="my-code" v-html="'나의 초대코드'" />
                <div class="code-container">
                    <div class="code">
                        <div class="code-text" v-html="myInvitationCode" />
                        <img
                            class="copy-icon"
                            @click="copyCode"
                            src="@/assets/images/icons/copy_white.png"
                            width="24px"
                            height="24px"
                        />
                    </div>
                </div>
                <div class="invite-btn" @click="copyCodeInvite">
                    <!-- <img class="talk m-r-5 p-2" src='@/assets/images/invitation-friend/kakao.png' alt="" /> -->
                    공유하기
                    <img
                        class="copy-icon"
                        @click="copyCode"
                        src="@/assets/images/copy.png"
                        width="20px"
                        height="40px"
                    />
                </div>
                <p class="c-primary f-13 m-t-10">
                    <!-- *제공된 무료 매칭권의 유효 기간은 90일이에요. <br /> -->
                    *가입 후 24시간 이내에 입력하지 않으면, 초대 코드를 입력할 수 없어요.
                </p>
                <!-- <div class="my-point">
                    내가 받은 포인트 : <span>{{ allInvitePoint.toLocaleString() }}원</span>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'

export default {
    name: 'InvitationFriend',
    components: {},
    data: () => ({
        selectedProduct: null,
        invitationCode: null,
        invitationPoint: 0,
        invitationCount: 0,
        hasEnteredInvitationCode: true,
        showHeaderTitle: false,
    }),
    computed: {
        me() {
            return this.$store.getters.me
        },
        gender() {
            return (this.$store.getters.me || {}).gender
        },
        isFemale() {
            return this.gender === 1
        },
        myInvitationCode() {
            return this.me ? this.me.profile.invitation_code : ''
        },
        shareInvitationCode() {
            const appLink = this.me ? 'https://abr.ge/jbft8x' : ''
            const invitationCode = this.me ? this.me.profile.invitation_code : ''
            return {
                appLink: appLink,
                invitationCode: invitationCode,
            }
        },
        allInvitePoint() {
            return this.$store.getters.items.all_invite_point
        },
        coinsetTop() {
            const coinset = document.getElementById('coin-set')
            if (coinset) {
                return coinset.offsetHeight * 0.63
            } else {
                return 150
            }
        },
    },
    created() {
        this.init()
    },
    beforeDestroy() {},
    methods: {
        async init() {
            this.hasEnteredInvitationCode = this.me.has_entered_invitation_code > 0
            this.invitationPoint = this.me.invitation_point
            this.invitationCount = this.me.invitation_count
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'invitationFriendPageEnter',
                },
            })
        },

        copyCode() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'InvitationPage_Click_Copy',
                },
            })

            this.$copyText(this.myInvitationCode)
            this.$toast.success('초대코드가 복사되었습니다!')
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'invitationFriendPageClickCopy',
                },
            })
        },
        copyCodeInvite() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'InvitationPage_Click_Copy',
                },
            })

            const shareData = this.shareInvitationCode
            this.$copyText(`앱링크: ${shareData.appLink}\n초대 코드: ${shareData.invitationCode}`)
            this.$toast.success('초대링크가 복사되었습니다!')
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'invitationFriendPageClickCopy',
                },
            })
        },
        copyCodeInCall() {
            try {
                // const { app_version: version, os } = this.$store.getters.device
                // if ((os === 'android' && version < '0.0.5') || (os === 'ios' && version < '0.0.3')) {
                //     this.$toast.error('앱 업데이트 후 사용할 수 있어요')
                //     return
                // }

                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'InvitationPage_Click_Phone',
                    },
                })

                this.$nativeBridge.postMessage({
                    action: 'getContactList',
                })

                // 웹에서 테스트용
                this.$store.commit('setContacts', [
                    { phoneNumbers: [{ number: '0123' }], familyName: '홍', givenName: '길동' },
                    { phoneNumbers: [{ number: '0124-0101-203' }], familyName: '홍', givenName: '길동' },
                    { phoneNumbers: [{ number: '010913-123-10659' }], familyName: '홍', givenName: '길동' },
                    { phoneNumbers: [{ number: '0126313' }], familyName: '홍', givenName: '길동' },
                    { phoneNumbers: [{ number: '0127' }], familyName: '홍123', givenName: '길동' },
                    { phoneNumbers: [{ number: '01299' }], givenName: '길동일이리라어' },
                    { phoneNumbers: [], familyName: '무명', givenName: '이아엉' },
                    { phoneNumbers: [{ num: '1023' }], familyName: '암것도', givenName: '나어ㅗ지마' },
                    { phoneNumbers: [{ number: '0129' }], familyName: '홍씨네' },
                ])
                this.$stackRouter.push({
                    name: 'ContactsListPage',
                })
            } catch (e) {
                this.$toast.error('앱 버전 정보를 로드하는데 실패했어요. 앱을 재실행해 주세요')
            }
        },
        copyCodeInKakao() {
            try {
                this.$nativeBridge.postMessage({
                    action: 'sendFirebaseEvent',
                    value: {
                        category: 'InvitationPage_Click_KaKao',
                    },
                })

                const { app_version: version, os } = this.$store.getters.device
                if ((os === 'android' && version < '0.0.5') || (os === 'ios' && version < '0.0.3')) {
                    this.$modal
                        .basic({
                            body: '카카오톡 초대를 이용하려면 앱 업데이트가 필요합니다. 업데이트 후 이용 부탁드려요!',
                            buttons: [
                                {
                                    label: '아니오',
                                    class: 'btn-default',
                                },
                                {
                                    label: '앱 업데이트 하기',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        .then(idx => {
                            if (idx) {
                                this.$nativeBridge.postMessage({
                                    action: 'openInAppBrowser',
                                    value: `https://abr.ge/nizopx`,
                                })
                            }
                        })

                    return
                }

                this.$nativeBridge.postMessage({
                    action: 'kakaoLink',
                    value: {
                        code: this.myInvitationCode,
                        name: this.$store.getters.me.name,
                        templateId: '64305',
                    },
                })
            } catch (e) {
                this.$toast.error('앱 버전 정보를 로드하는데 실패했어요. 앱을 재실행해 주세요')
            }
        },
        onInputCode(event) {
            this.invitationCode = event.target.value
        },
        async onClickItem(item) {
            if (this.invitationPoint - item.cost < 0) {
                this.$toast.error('추천 포인트가 부족해요')
                return
            }

            try {
                this.loading = true
                const payload = { ptype: item.key }
                const { msg } = await userService.useInvitationPoint(this.me.user_id, payload)
                this.$modal.basic({
                    title: '사용 완료',
                    body: msg,
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                // this.$alert(msg)
                this.invitationPoint = this.invitationPoint - item.cost
                this.$store.dispatch('loadItems')
                this.$store.dispatch('loadMe')
            } catch (e) {
                this.$toast.error(e.data.msg)
            } finally {
                this.loading = false
            }
        },
        onScroll(event) {
            if (event.target.scrollTop >= 52) {
                this.showHeaderTitle = true
            } else {
                this.showHeaderTitle = false
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.invitation-friend {
    height: 100%;
    display: flex;
    flex-direction: column;
    .top-section,
    .bottom-section {
        height: 55%;
        background: #ffffff;
        position: relative;
        top: 0;
    }
    .top-section {
        .head {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: auto 100%;
            width: 100%;
            height: 130px;
            padding: 20px 17px 4px;

            @media (max-width: 299px) {
                padding: 10px 17px 4px;
                height: 110px;
            }

            @media (min-width: 300px) and (max-width: 389px) {
                padding: 20px 17px 4px;
                height: 130px;
            }

            .head-btn {
                width: fit-content;
                border-radius: 100px;
                background-color: #f4faff;
                padding: 2px 8px;
                font-size: 12px;
                margin: 0 auto;
                color: $blue-skymarry;
            }
            .headline {
                font-size: 32px;
                @include spoqa-f-bold;
                color: black;
                line-height: 1.38;
                text-align: center;
                margin-top: 14px;
            }
        }
        .desc {
            position: relative;
            z-index: 1;
            text-align: center;
            padding: 0 16px;
            color: black;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;

            @media (max-width: 299px) {
                line-height: 20px;
            }

            @media (min-width: 300px) and (max-width: 389px) {
                line-height: 22px;
            }

            .desc-sec {
                line-height: 16px;
            }
        }
        .title {
            @include f-medium;
            font-size: 24px;
            color: black;
            margin-left: 16px;
            margin-top: 12px;
        }
    }
    .bottom-section {
        height: 45%;
        background: #fff9ef;
        position: relative;
        display: flex;
        width: 100%;
        align-items: flex-end;
        .coin-set {
            position: absolute;
            top: -180px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 0;

            .coin-set {
                width: 100%;
                position: absolute;
                top: -150px;
            }
        }
        .body {
            width: 100%;
            margin: 120px 17px 0;
            margin-bottom: 48px;

            .my-code {
                font-size: 12px;
                text-align: center;
                @include f-medium;
                color: #111111;
                margin-bottom: 4px;
            }
            .my-point {
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
                text-align: center;
                color: #111111;
                span {
                    font-weight: 700;
                }
            }
            .code-container {
                margin-bottom: 12px;
                @include center;

                .code {
                    padding: 12px 20px;

                    width: 100%;
                    height: 68px;
                    background: #070a0e;
                    border-radius: 8px;
                    font-weight: 700;
                    font-size: 32px;
                    line-height: 44px;
                    text-align: center;
                    color: #ffffff;

                    text-align: center;
                    display: flex;
                    // flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    .code-text {
                        font-size: 32px;
                        line-height: 44px;
                        text-align: center;
                        color: #ffffff;
                        font-weight: 700;
                    }
                    img.copy-icon {
                        margin-left: 8px;
                    }
                }

                .row {
                    text-align: center;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
            }
            .invite-btn {
                width: 100%;
                padding: 11px;
                border-radius: 10px;
                background-color: rgb(234, 209, 220);
                font-size: 18px;
                font-weight: 500;
                color: black;
                @include spoqa-f-medium;
                @include center;

                img {
                    height: 16px;
                }
            }
            .invite-btn-call {
                @extend .invite-btn;
                color: white;
                background: #2b343e;
            }
        }
    }
    .bottom-border {
        border-bottom: 1px solid $grey-02;
    }
}
</style>
