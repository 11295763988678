<template>
    <div class="product-pay">
        <div class="container">
            <section class="section m-b-28">
                <div class="title">결제 상품</div>
                <div class="grey-box">
                    <span>{{ selectedProduct.name }}</span>
                </div>
                <div v-if="selectedProduct && selectedProduct.code === 'pro_manager'" class="title-desc">
                    정기 결제 상품이 아니니 안심하세요.<br />(1개월 후 연장을 원하실 경우 다시 결제해야 하는
                    상품입니다.)
                </div>
            </section>
            <UseCoupon />
            <ProductBill :selected-product="selectedProduct" />
            <!-- <SelectPayMethod @select="clickPayMethod" /> -->
            <div id="payment-method"></div>
            <!-- 이용약관 UI -->
            <div id="agreement"></div>
            <div class="refund">
                <div class="refund-title f-16 m-b-10">환불 및 유의 사항</div>
                <ul class="refund m-l-20 m-r-20">
                    <li
                        v-for="item in refundDescriptions"
                        :key="item.id"
                        class="refund-content f-13"
                        style="list-style-type: disc"
                        v-html="item.description"
                    />
                </ul>
            </div>
            <div class="check">
                <img :src="notibox" alt="noti-box" width="24px" height="24px" @click="onClickCheck" />
                <div class="noti-content m-l-8">환불 및 유의 사항을 확인했으며 동의합니다.</div>
            </div>
        </div>
        <div class="purchase-btn">
            <!-- <button class="bottom-btn f-18" @click="clickPay" v-html="label" /> -->
            <button @click="clickPay" class="button bottom-btn f-18" id="payment-button">결제하기</button>
        </div>
    </div>
</template>

<script>
import { loadPaymentWidget } from '@tosspayments/payment-widget-sdk'
import { nanoid } from 'nanoid'
import productConstants from '@/assets/constants/products'
// import RefundPolicy from '@/routes/prepay/components/RefundPolicy'
import ProductBill from '@/routes/prepay/components/ProductBill'
import UseCoupon from '@/routes/prepay/components/UseCoupon'
// import SelectPayMethod from '@/routes/prepay/components/SelectPayMethod'
import productService from '@/services/product'
// import orderService from '@/services/order'

export default {
    name: 'PaymentMethodsPage',
    components: { ProductBill, UseCoupon },
    data: () => ({
        order: null,
        selected: null,
        checked: false,
        paymentWidget: null,
        paymentMethodWidget: null,
        // TODO: clientKey는 개발자센터의 결제위젯 연동 키 > 클라이언트 키로 바꾸세요.
        // TODO: customerKey는 구매자와 1:1 관계로 무작위한 고유값을 생성하세요.
        clientKey: null,
        customerKey: null,
        refundDescriptionLists: [
            {
                id: 1,
                ptype: 'ticket_plan',
                description: `매칭권으로 횟수의 제한 없이 프로필을 수락할 수 있으며, 매칭권 1장당 1회의 약속(만남)을 가질 수 있습니다.`,
            },
            {
                id: 2,
                ptype: 'ticket_plan',
                description: `N회권을 구매 후 일부만 사용한 경우 부분 환불 가능합니다. 단, 환불 금액은 ‘N회권 결제 금액 - 사용한 매칭권 개수 * 1회 매칭권의 정가’로 계산합니다.`,
            },
            {
                id: 3,
                ptype: 'ticket_plan',
                description: `결제 금액은 실제로 지불한 금액을 의미합니다.`,
            },
            {
                id: 4,
                ptype: 'ticket_plan',
                description: `매칭권의 정가는 할인이 적용되지 않은 정가를 의미합니다.`,
            },
            {
                id: 5,
                ptype: 'ticket_plan',
                description: `구매한 매칭권의 유효 기간은 다음과 같습니다. 유효 기간 안에 사용하지 않은 매칭권은 소멸합니다. 단, 휴면 시 유효 기간은 휴면한 기간만큼 자동으로 연장됩니다.<br/> 1) 1장 구매 시: 50일<br/> 2) 3장 구매 시: 100일 <br/>3) 5장 구매 시: 150일`,
            },
            {
                id: 6,
                ptype: 'ticket_plan',
                description: `휴면은 이용 기간 내에 사유와 관계없이 '1회' 가능하며 최대 180일까지 가능합니다.`,
            },
            {
                id: 7,
                ptype: 'ticket_plan',
                description: `매칭권은 양도 불가합니다.`,
            },
            {
                id: 8,
                ptype: 'ticket_plan',
                description: `환불은 환불을 신청하신 월의 마지막 주 수요일에 이루어집니다. 마지막 주 수요일 이후 환불을 신청하신 경우, 환불은 다음 월의 마지막 주 수요일에 이루어집니다.`,
            },
            {
                id: 9,
                ptype: 'ticket_plan',
                description: `사용한 매칭권은 환불이 불가합니다. 매칭권 사용의 기준은 다음과 같습니다. 매칭권을 구매한 이후 ① 매니저에게 제공받은 프로필을 수락하여 상대방의 매니저가 상대방에게 의향을 물어본 상태이거나, ② 이성과 약속 잡기를 진행한 경우 매칭권을 사용한 것으로 간주합니다.
약속 잡기를 진행한 이후 약속이 취소된 경우에도 매칭권은 사용한 것으로 간주됩니다.단, 본인의 귀책 사유가 아닌 상대방 귀책 사유로 약속이 취소된 경우에는 사용한 매칭권을 재지급해 드리며, 재지급된 매칭권을 사용하여 다른 사람과 약속을 잡을 수 있습니다.`,
            },
            {
                id: 10,
                ptype: 'ticket_plan',
                description: `복수의 매칭권을 보유하고 있는 경우, 1장의 매칭권을 사용하여 약속 잡기를 진행한 이후 새로운 프로필을 추가적으로 수락하면 2번째 매칭권을 사용한 것으로 간주됩니다.`,
            },
            {
                id: 11,
                ptype: 'ticket_plan',
                description: `이용 규칙을 위배(가벼운 목적, 비속어 사용, 노쇼, 약속 취소 등)하여 이용 정지 처리된 경우 환불하지 않습니다.`,
            },
            {
                id: 1,
                ptype: 'rate_plan',
                description: `기간권 상품의 이용 기간은 결제 완료 직후부터 시작됩니다.`,
            },
            {
                id: 2,
                ptype: 'rate_plan',
                description: `약속 잡기 진행 시 추가로 발생하는 비용은 일체 없습니다. 그러나 매너수락으로 진행하는 경우 별도의 티켓 구매가 필요합니다.`,
            },
            {
                id: 3,
                ptype: 'rate_plan',
                description: `서비스 중도 해지 시에 공정거래위원회 약관에 따라 아래와 같이 환불 가능합니다.<br />1. 서비스 이용 개시 전 중도 해지 시 환불 금액 : 100% 전액 환불<br />2. 서비스 이용 개시 후 중도 해지 시 환불 금액 : 2. 결제금액 - 기간권 정가 * 서비스 이용 Fee 10% - 기간권 정가(실제 이용 개월수/총 상품 이용개월수)<br />- 구매 후 제공받은 이성의 프로필을 열람하거나, 이성과 약속 잡기를 시작한 경우 서비스 이용 개시로 간주합니다.<br />- 구매 후 7일이 경과한 경우, 서비스 이용 개시로 간주합니다.<br />- 이용 개월수는 ‘주’ 혹은 ‘일’ 단위가 아닌 ‘월’ 단위로 측정합니다. 가령, 처음 프로필을 수락한 시점부터 1개월을 이용한 것으로 간주합니다.`,
            },
            {
                id: 4,
                ptype: 'rate_plan',
                description: `"1개월 이용권" 및 한정 특가 상품(40% 이상 할인 상품)은 서비스 이용개시 후 환불이 불가능합니다. 또한, 이용 규칙을 위배(가벼운 목적, 비속어 사용 등)하여 이용 정지 처리된 경우 환불이 불가합니다.`,
            },
            {
                id: 5,
                ptype: 'rate_plan',
                description: `휴면은 기간권 이용 기간 내에 어떠한 사유와 관계 없이 ‘1회’ 가능합니다. 단, "1개월 이용권"은 휴면이 불가능합니다.<br />1. 3개월 이용권 : 최대 30일 휴면 가능<br />2. 6개월 이용권 : 최대 90일 휴면 가능`,
            },
            {
                id: 5,
                ptype: 'rate_plan',
                description: `기간권은 양도가 불가능합니다.`,
            },
            {
                id: 6,
                ptype: 'rate_plan',
                description: `환불은 매 월 마지막 주 수요일에 순차적으로 진행되고 있습니다. 마지막 주 수요일 이후에 환불 신청을 하셨을 경우 다음 달로 이월됩니다.`,
            },
            {
                id: 1,
                ptype: 'subscription_plan',
                description: `멤버십과 매칭권은 별도 구매 상품입니다.`,
            },
            {
                id: 2,
                ptype: 'subscription_plan',
                description: `멤버십 이용 기간은 결제 완료 직후부터 시작됩니다.`,
            },
            {
                id: 3,
                ptype: 'subscription_plan',
                description: `멤버십 중도 해지 시에 공정거래위원회 약관에 따라 아래와 같이 환불 가능합니다. <br />1. 서비스 개시 전 중도 해지 시 환불 금액: 결제금액 100% 전액 <br />2. 서비스 개시 후 중도 해지 시 환불 금액 : 결제금액 - 멤버십 정가*매니저 서비스 비용 20% - 멤버십 정가*(이용개월수/총 멤버십 개월수)`,
            },
            {
                id: 4,
                ptype: 'subscription_plan',
                description: `서비스 개시의 기준:  메리핏 멤버십의 경우는 '첫번째 프로필'을 받은 시점, 메리핏 플러스 멤버십의 경우 매니저로부터 피드백 및 분석 자료를 받은 시점으로 간주합니다.`,
            },
            {
                id: 5,
                ptype: 'subscription_plan',
                description: `단, 이용 규칙을 위배(가벼운 목적, 비속어 사용, 노쇼 약속 취소 등)하여 이용 정지 처리된 경우 환불하지 않습니다.`,
            },
            {
                id: 6,
                ptype: 'subscription_plan',
                description: `이용 개월 수는 '주' 혹은 '일' 단위가 아닌 '월' 단위로 측정합니다. 가령, 첫번째 프로필을 받은 시점부터 1개월을 이용한 것으로 간주합니다.`,
            },
            {
                id: 7,
                ptype: 'subscription_plan',
                description: `멤버십 정가는 할인이 적용되지 않은 정가를 의미합니다.`,
            },
            {
                id: 8,
                ptype: 'subscription_plan',
                description: `결제금액은 실제로 지불한 금액을 의미합니다.`,
            },
            {
                id: 9,
                ptype: 'subscription_plan',
                description: `멤버십 프로그램은 만남 횟수와 매칭 성공의 확률을 높여주고, 매니저는 최선을 다해 노력합니다만, 만남 성사 확률과 매칭 횟수는 개인의 매력과 눈높이 등 다양한 변수에 영향을 받기 때문에100% 만남 성사를 보장하는 프로그램은 아닙니다.`,
            },
            {
                id: 10,
                ptype: 'subscription_plan',
                description: `휴면은 멤버십 이용 기간 내에 어떠한 사유와 관계 없이 '1회' 가능하며 최대 180일까지 가능합니다.`,
            },
            {
                id: 11,
                ptype: 'subscription_plan',
                description: `멤버십 프로그램과 별도로 구매한 매칭권 및 기타 상품음 각 상품의 별도 환불 정책을 따릅니다.`,
            },
            {
                id: 12,
                ptype: 'subscription_plan',
                description: `멤버십 상품은 양도 불가합니다.`,
            },
            {
                id: 13,
                ptype: 'subscription_plan',
                description: `환불은 환불을 신청하신 월의 마지막 주 수요일에 이루어집니다.`,
            },
            {
                id: 14,
                ptype: 'subscription_plan',
                description: `마지막 주 수요일 이후 환불을 신청하신 경우, 환불은 다음 월의 마지막 주 수요일에 이루어집니다.`,
            },
            {
                id: 1,
                ptype: 'package_plan',
                description: `멤버십 기간 내에 매칭권을 모두 사용한 경우, 매칭권만 별도로 추가 구매하여 사용 가능합니다.`,
            },
            {
                id: 2,
                ptype: 'package_plan',
                description: `멤버십 이용 기간은 결제 완료 직후부터 시작됩니다.`,
            },
            {
                id: 3,
                ptype: 'package_plan',
                description: `이용기간 1개월은 30일로 간주합니다.`,
            },
            {
                id: 4,
                ptype: 'package_plan',
                description: `멤버십 중도 해지 시에 공정거래위원회 약관에 따라 아래와 같이 환불 가능합니다. <br />1. 서비스 개시 전 (매니저로부터 프로필을 한 번도 받지 않은 경우) 중도 해지 환불 금액: 100% 전액 <br />2. 서비스 개시 후 (프로필을 받은 이후) 중도 해지 시 환불 금액 : 결제금액 - 패키지 정가*매니저 서비스 비용 20% - 패키지 정가*(이용개월수/총 멤버십 개월수)`,
            },
            {
                id: 5,
                ptype: 'package_plan',
                description: `서비스 개시의 기준:  메리핏 멤버십의 경우는 '첫번째 프로필'을 받은 시점, 메리핏 플러스 멤버십의 경우 매니저로부터 피드백 및 분석 자료를 받은 시점으로 간주합니다.`,
            },
            {
                id: 6,
                ptype: 'package_plan',
                description: ` 단, 이용 규칙을 위배(가벼운 목적, 비속어 사용 등)하여 이용 정지 처리된 경우 환불하지 않습니다.`,
            },
            {
                id: 7,
                ptype: 'package_plan',
                description: `패키지 정가는 할인이 적용되지 않은 정가를 의미합니다. 즉, 멤버십의 정가와 매칭권 5장의 정가를 더한 가격이 패키지 정가입니다.`,
            },
            {
                id: 8,
                ptype: 'package_plan',
                description: `결제금액은 실제로 지불한 금액을 의미합니다.`,
            },
            {
                id: 9,
                ptype: 'package_plan',
                description: `'멤버십+매칭권' 패키지 상품의 경우 멤버십 환불 정책과 매칭권 환불 정책을 각각 적용하는 것이 아니라, 패키지 상품의 환불 정책을 따릅니다. 즉, 매칭권 사용 개수와 관계 없이 서비스 개시 여부와 이용 개월수에 따라 환불 금액이 결정됩니다.`,
            },
            {
                id: 10,
                ptype: 'package_plan',
                description: `이용 개월 수는 '주' 혹은 '일'단위가 아닌 '월' 단위로 측정합니다. 가령, 첫번째 프로필을 받은 시점부터 1개월을 이용한 것으로 간주합니다.`,
            },
            {
                id: 11,
                ptype: 'package_plan',
                description: `멤버십 프로그램은 만남 횟수와 매칭 성공의 확률을 높여주고, 매니저는 최선을 다해 노력합니다만, 만남 성사 확률과 매칭 횟수는 개인의 매력과 눈높이 등 다양한 변수에 영향을 받기 때문에100% 만남 성사를 보장하는 프로그램은 아닙니다.`,
            },
            {
                id: 12,
                ptype: 'package_plan',
                description: `휴면은 멤버십 이용 기간 내에 어떠한 사유와 관계 없이 '1회' 가능하며 최대 180일까지 가능합니다.`,
            },
            {
                id: 13,
                ptype: 'package_plan',
                description: `부여된 매칭권의 유효 기간은 180일입니다. 멤버십 구독 기간이 종료되어도 매칭권의 유효기간이 남아있는 경우 무료 멤버십 등을 이용해 매칭에 사용할 수 있습니다. (유효 기간 안에 사용하지 않은 매칭권의 경우 소멸하며, 휴면 시 유효 기간은 휴면한 기간만큼 자동으로 연장됩니다.)`,
            },
            {
                id: 14,
                ptype: 'package_plan',
                description: `패키지 상품은 양도 불가합니다.`,
            },
            {
                id: 15,
                ptype: 'package_plan',
                description: ` 환불은 환불을 신청하신 월의 마지막 주 수요일에 이루어집니다.`,
            },
            {
                id: 16,
                ptype: 'package_plan',
                description: `마지막 주 수요일 이후 환불을 신청하신 경우, 환불은 다음 월의 마지막 주 수요일에 이루어집니다.`,
            },

            {
                id: 1,
                ptype: 'pro_manager',
                description: `구독 기간에 표시된 '1개월'은 4주를 의미합니다. (1개월 = 4주, 3개월 = 12주)`,
            },
            {
                id: 2,
                ptype: 'pro_manager',
                description: `프로 매니저 구독 상품과 매칭권은 별도 구매 상품입니다.`,
            },
            {
                id: 3,
                ptype: 'pro_manager',
                description: `프로 매니저 구독 상품은 양도 불가합니다.`,
            },
            {
                id: 4,
                ptype: 'pro_manager',
                description: `프로 매니저 구독 기간은 매니저 배정 직후부터 시작됩니다.`,
            },
            {
                id: 5,
                ptype: 'pro_manager',
                description: `프로 매니저 구독 중도 해지 시 공정거래위원회 약관에 따라 아래와 같이 환불 가능합니다.`,
            },
            {
                id: 6,
                ptype: 'pro_manager',
                description: `1)서비스 개시 전 중도 해지 시 환불 금액: 결제 금액 100% 전액`,
            },
            {
                id: 7,
                ptype: 'pro_manager',
                description: `2)서비스 개시 후 중도 해지 시 환불 금액: 결제 금액 - 상품 정가 * 매니저 서비스 비용 20% - 상품 정가 * (이용 개월 수 / 총 구독 개월 수)`,
            },
            {
                id: 8,
                ptype: 'pro_manager',
                description: `결제 금액은 실제로 지불한 금액을 의미합니다.`,
            },
            {
                id: 9,
                ptype: 'pro_manager',
                description: `상품 정가는 할인이 적용되지 않은 정가를 의미합니다.`,
            },
            {
                id: 10,
                ptype: 'pro_manager',
                description: `서비스 개시(이용)의 기준: 배정받은 매니저로부터 '첫 번째 프로필'을 받은 경우 서비스 개시로 간주합니다.`,
            },
            {
                id: 11,
                ptype: 'pro_manager',
                description: `이용 개월 수는 '주' 혹은 '일' 단위가 아닌 '월' 단위로 측정합니다. 가령, 첫 번째 프로필을 받은 시점부터 1개월을 이용한 것으로 간주합니다. 1개월(4주) 초과 후 프로필을 수령한 경우 2개월을 이용한 것으로 간주합니다.`,
            },
            {
                id: 12,
                ptype: 'pro_manager',
                description: `이용 규칙을 위배(가벼운 목적, 비속어 사용, 노쇼, 약속 취소 등)하여 이용 정지 처리된 경우 환불하지 않습니다.`,
            },
            {
                id: 13,
                ptype: 'pro_manager',
                description: `프로 매니저 구독 시, 매니저는 니즈에 맞는 프로필을 추천해 주고, 매칭률을 올리기 위해 최선을 다하며 대부분의 회원이 원하는 이성과 매칭에 성공합니다. 다만, 매칭률은 개인의 매력과 눈높이에 따라 개인마다 편차가 존재할 수 있으며, 본 구독 상품은 100% 매칭 성사를 보장하지 않습니다.`,
            },
            {
                id: 14,
                ptype: 'pro_manager',
                description: `휴면은 프로 매니저 구독 기간 내에 어떠한 사유와 관계없이 '1회' 가능하며 최대 180일까지 가능합니다.`,
            },
            {
                id: 15,
                ptype: 'pro_manager',
                description: `프로 매니저 구독 상품과 별도로 구매한 매칭권 및 기타 상품은 각 상품의 별도 환불 정책을 따릅니다.`,
            },
            {
                id: 16,
                ptype: 'pro_manager',
                description: `환불은 환불을 신청하신 월의 마지막 주 수요일에 이루어집니다. 마지막 주 수요일 이후 환불을 신청하신 경우, 환불은 다음 월의 마지막 주 수요일에 이루어집니다.`,
            },
        ],
    }),
    async mounted() {
        window.addEventListener('message', this.onMessageFromRN)
        document.addEventListener('message', this.onMessageFromRN)

        // ------  결제위젯 초기화 ------
        // @docs https://docs.tosspayments.com/reference/widget-sdk#sdk-설치-및-초기화
        this.clientKey = process.env.VUE_APP_TOSS_PAYMENTS_CLIENT_KEY
        this.customerKey = this.$store.getters.me.shorten

        this.paymentWidget = await loadPaymentWidget(this.clientKey, this.customerKey, {
            brandpay: {
                // Access Token 발급에 사용되는 리다이렉트 URL
                redirectUrl: process.env.VUE_APP_API_URL + 'v2/users/toss_payments_auth',
            },
        })
        // ------  결제 UI 렌더링 ------
        // @docs https://docs.tosspayments.com/reference/widget-sdk#renderpaymentmethods선택자-결제-금액-옵션
        this.paymentMethodWidget = await this.paymentWidget.renderPaymentMethods(
            '#payment-method',
            { value: parseInt(this.price.sellingPrice.replace(/,/g, ''), 10) },
            { variantKey: 'widgetA' }
        )
        // ------  이용약관 UI 렌더링 ------
        // @docs https://docs.tosspayments.com/reference/widget-sdk#renderagreement선택자-옵션
        this.paymentWidget.renderAgreement('#agreement', { variantKey: 'AGREEMENT' })

        this.paymentMethodWidget.on('ready', () => {
            this.inputEnabled = true
        })
    },
    beforeDestroy() {
        window.removeEventListener('message', this.onMessageFromRN)
        document.removeEventListener('message', this.onMessageFromRN)
    },
    computed: {
        selectedProduct() {
            return this.$store.getters.selectedProduct
        },
        price() {
            return this.$store.getters.price
        },
        selectedCoupon() {
            return this.$store.getters.selectedCoupon
        },
        selectedPoint() {
            return this.$store.getters.selectedPoint
        },
        category() {
            return this.$store.getters.selectedPlan
        },
        planName() {
            if (this.category.category === 'unlimited') {
                return `${this.$translate(this.category.category.toUpperCase())} - ${
                    this.selectedProduct.valid_week / 4 > 6 ? `6+3개월` : `${this.selectedProduct.valid_week / 4}개월`
                }`
            }
            return `${this.$translate(this.category.category.toUpperCase())} - ${
                this.selectedProduct.amount > 6 ? `6+3회` : `${this.selectedProduct.amount}회`
            }`
        },
        refundPolicy() {
            return productConstants.refundPolicy
        },
        label() {
            const price = this.$store.getters.price.sellingPrice

            return `${price}원 결제하기`
        },
        // disabled() {
        //     return this.selected === null
        // },
        isPostPaid() {
            //  후불제일때 product_id===42 로 구별하는데 조건 더 필요
            return (this.order || {}).product_id === 42
        },
        refundDescriptions() {
            if (this.selectedProduct.code === 'pro_manager') {
                return this.refundDescriptionLists.filter(l => l.ptype === 'pro_manager')
            }
            return this.refundDescriptionLists.filter(l => l.ptype === this.category.ptype)
        },
        notibox() {
            if (this.checked === true) {
                return require('@/assets/images/icons/notibox_active.png')
            } else {
                return require('@/assets/images/icons/notibox_inactive.png')
            }
        },
    },
    methods: {
        onMessageFromRN(event) {
            const data = this.$mustParse(event.data)
            if (!data || !data.action) return

            if (data.action === 'orderComplete') {
                this.$loading(true)
                setTimeout(() => {
                    this.checkOrder()
                }, 1000)
            }
        },
        sendAirbiridgeEvents() {
            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: `Complete_Purchase`,
                    option: {
                        value: this.order.paid_price,
                        customAttributes: {
                            gender: this.$store.getters.me.gender === 0 ? 'Male' : 'Female',
                            paid_price: this.order.paid_price,
                        },
                    },
                },
            })

            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: `Complete_Payment`,
                },
            })
        },
        sendFirebaseEvents() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: `Complete_Purchase`,
                    option: {
                        paid_price: this.order.paid_price,
                        gender: this.$store.getters.me.gender === 0 ? 'Male' : 'Female',
                    },
                },
            })

            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: `Complete_Payment`,
                },
            })
        },
        // async checkOrder() {
        //     if (!this.order) return

        //     try {
        //         const { msg } = await orderService.get(this.order.id)
        //         await this.$store.dispatch('loadMe')
        //         await this.$store.dispatch('loadChats', true)
        //         this.$loading(false)

        //         localStorage.setItem('check-expire-notice', false)
        //         localStorage.setItem('order', JSON.stringify(this.order))
        //         setTimeout(() => {
        //             this.$router.push({
        //                 name: 'CompletePaymentPage',
        //             })
        //             this.sendAirbiridgeEvents()
        //             this.sendFirebaseEvents()
        //         }, 2000)
        //         this.$router.push({
        //             name: 'PaymentLoadingPage',
        //         })
        //         console.log(msg)
        //     } catch (e) {
        //         this.$toast.error(e.data.msg)
        //     }
        // },
        clickPayMethod(id) {
            this.selected = id
        },
        async clickPay() {
            // return console.log('this.selectedProduct', this.selectedProduct);
            try {
                // if (this.selected === null) {
                //     this.$modal.basic({
                //         body: '결제 수단을 선택해주세요.',
                //         buttons: [
                //             {
                //                 label: 'CONFIRM',
                //                 class: 'btn-primary',
                //             },
                //         ],
                //     })
                //     return
                // }
                if (this.checked === false) {
                    this.$modal.basic({
                        body: '환불 및 휴면 정책에 동의해주세요.',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    return
                }
                // if (this.selected === 0) {
                //     const payload = {
                //         payment_type: 'credit',
                //         user_id: this.selectedCoupon ? this.$store.getters.me.id : null,
                //         discount_code: this.selectedCoupon ? this.selectedCoupon.discount_code : null,
                //         name: this.$store.getters.me.name,
                //         phone_number: this.$store.getters.me.phone_number,
                //         discount_point: this.selectedPoint,
                //     }
                //     productService
                //         .createOrder(this.selectedProduct.id, payload)
                //         .then(({ order, checkout_page }) => {
                //             this.order = order
                //             this.$nativeBridge.postMessage({
                //                 action: 'pgPurchase',
                //                 value: checkout_page,
                //             })
                //             // this.$router.push({ name: 'CompleteMembershipPaymentPage', params: { order: this.order } })
                //         })
                //         .catch(e => this.$toast.error(e.data.msg))
                // } else {
                if (this.paymentWidget) {
                    const payload = {
                        payment_type: 'toss_payments',
                        user_id: this.selectedCoupon ? this.$store.getters.me.id : null,
                        discount_code: this.selectedCoupon ? this.selectedCoupon.discount_code : null,
                        name: this.$store.getters.me.name,
                        phone_number: this.$store.getters.me.phone_number,
                        discount_point: this.selectedPoint,
                    }
                    if (this.selectedCoupon || this.selectedPoint > 0) {
                        this.paymentMethodWidget.updateAmount(parseInt(this.price.sellingPrice.replace(/,/g, ''), 10))
                    }
                    productService
                        .createOrder(this.selectedProduct.id, payload)
                        .then(async ({ order, checkout_page }) => {
                            this.order = order
                            const orderId = nanoid()
                            // this.$nativeBridge.postMessage({
                            //     action: 'pgPurchase',
                            //     value: checkout_page,
                            // })
                            localStorage.setItem('check-expire-notice', false)
                            localStorage.setItem('order', JSON.stringify(this.order))
                            localStorage.setItem('orderId', orderId)
                            localStorage.setItem('product', this.selectedProduct)

                            // ------ '결제하기' 버튼 누르면 결제창 띄우기 ------
                            // 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
                            // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
                            // @docs https://docs.tosspayments.com/reference/widget-sdk#requestpayment결제-정보
                            await this.paymentWidget.requestPayment({
                                orderId: orderId,
                                orderName: this.selectedProduct.name,
                                customerName: this.$store.getters.me.name,
                                customerEmail: this.$store.getters.me.email,
                                customerMobilePhone: this.$store.getters.me.phone_number,
                                successUrl: `${window.location.origin}/pre-payment/loading`,
                                failUrl: `${window.location.origin}${
                                    this.selectedProduct.code === 'pro_manager'
                                        ? '/membership/manager-introduction'
                                        : this.selectedProduct.ptype === 'subscription_plan'
                                        ? '/membership/membership-introduction'
                                        : '/pre-payment/main'
                                }`,
                            })
                        })
                }
                // }
            } catch (e) {
                console.error(e)
                this.$toast.error(e.data)
            }
        },
        onClickCheck() {
            this.checked = !this.checked
        },
    },
}
</script>

<style scoped lang="scss">
.product-pay {
    .container {
        height: calc(100% - 80px);
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 16px 16px 100px 16px;

        .refund {
            .refund-title {
                margin-top: 56px;
                color: #666b70;
                font-weight: 400;
            }
            .refund-content {
                color: #94999e;
                font-weight: 500;
                line-height: 23.4px;

                .refund-highlight {
                    color: #7b7b7b;
                    font-weight: 600;
                }
            }
        }
    }
    ::v-deep.title {
        font-size: 16px;
        line-height: 26px;
        color: black;
        margin-bottom: 8px;
    }

    .grey-box {
        margin-top: 8px;
        background: #f8eee4;
        padding: 12px;
        font-size: 16px;
        line-height: 26px;
        border-radius: 12px;
        display: flex;
        align-items: center;

        .img-container {
            width: 64px;
            color: $grey-04;
            margin-right: 8px;
            display: flex;
            align-items: center;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .title-desc {
        margin-top: 6px;
        padding-left: 4px;
        font-size: 10px;
        color: #d45757;
    }

    .purchase-btn {
        margin: 16px 20px;
        height: 48px;
        .bottom-btn {
            width: 100%;
            height: 100%;
            border: none;
            background: #151360;
            border-radius: 36px;
            color: #f7f7f4;
        }
    }

    .check {
        margin-top: 30px;
        text-align: center;
        @include center;
    }
}
</style>
