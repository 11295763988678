<template>
    <div v-if="!loading" class="user-profile bg-white m-16" style="position: relative; border-radius: 8px">
        <div
            v-if="this.user.id !== this.me.id && !likeMe"
            class="like-icon"
            style="z-index: 1; right: 3%; bottom: 3%; position: absolute"
        >
            <img @click="onClickLikeIcon()" class="like" :src="likeIcon()" alt="like-icon" width="90px" height="90px" />
        </div>
        <div class="personal-story">
            <div v-if="userPersonalStories" class="title-box">
                <span> {{ generateTitle() }} </span>
            </div>
            <div v-if="userPersonalStories" class="answer-box">
                <p class="f-18 f-gowun-dodum" style="white-space: pre-wrap">{{ generateAnswer() }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import profileService from '@/services/profile'
import scheduleService from '@/services/schedule'

export default {
    name: 'UserPersonalStories',
    props: {
        user: {
            type: Object,
            required: true,
        },
        from: String,
        type: String,
        message: Object,
        likeActivate: Boolean,
        likeMe: Boolean,
        profileUpdate: {
            type: Object,
            required: false,
        },
        allPhotos: Array,
        needExtraChargingPoint: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data: () => ({
        userPersonalStories: null,
        personalStories: null,
        index: null,
        loading: null,
    }),
    computed: {
        me() {
            return this.$store.getters.me
        },
        profile() {
            return this.profileUpdate || null
        },
        // likeIcon() {
        //     return require(`@/assets/images/icons/like_inactive.png`)
        // }
        chatUser() {
            return this.$store.getters.chat.user.name
        },
        userRatePlans() {
            return this.$store.getters.userRatePlans || {}
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            try {
                this.loading = true
                if (this.user.id === this.me.id) {
                    const res = await profileService.userPersonalStories(this.me.id)
                    this.userPersonalStories = res.data
                } else {
                    const res = await profileService.userPersonalStories(this.user.id)
                    this.userPersonalStories = res.data
                }
                const personalStories = await profileService.personalStories()
                this.personalStories = personalStories

                if (this.type === 'first') {
                    this.index = 0
                } else if (this.type === 'second') {
                    this.index = 1
                } else if (this.type === 'third') {
                    this.index = 2
                }
            } catch (e) {
                console.error(e)
            } finally {
                this.loading = false
            }
        },
        generateTitle() {
            if (!this.personalStories) return
            if (!this.profile) {
                const title = this.personalStories.find(
                    o => o.id === this.userPersonalStories[this.index].personal_story_id
                ).name
                return title
            } else {
                const title = this.personalStories.find(
                    o => o.id === this.profile.user_personal_stories[this.index].personal_story_id
                ).name
                return title
            }
        },
        generateAnswer() {
            if (!this.profile) {
                if (!this.userPersonalStories) return
                const answer = this.userPersonalStories[this.index].content
                return answer
            } else {
                if (!this.profile.user_personal_stories) return
                const answer = this.profile.user_personal_stories[this.index].content
                return answer
            }
        },
        likeIcon() {
            if (
                !this.likeActivate &&
                this.user.photo_histories &&
                this.user.photo_histories.accepted_story_id &&
                this.user.photo_histories.accepted_story_id === this.userPersonalStories[this.index].id
            ) {
                return require(`@/assets/images/icons/like_active.png`)
            } else {
                return require(`@/assets/images/icons/like_inactive.png`)
            }
        },
        async initSchedule() {
            try {
                const aUser = this.user.gender === 1 ? this.user.id : this.me.id
                const bUser = this.user.gender === 1 ? this.me.id : this.user.id
                const { res } = await scheduleService.initSchedule({
                    a_user_id: aUser,
                    b_user_id: bUser,
                })
                console.log('res', res)
                if (res === 'fail') {
                    this.$toast.error('이미 약속잡기가 진행 중이에요')
                }
            } catch (e) {
                console.log(e)
            }
        },
        async onClickLikeIcon() {
            console.log('onClickLikeIcon stories')
            if (this.chatUser === '고객센터' && this.me.profile.status === 'confirmed') {
                this.$modal
                    .basic({
                        body: '해당 회원의 프로필을 퍼스널매니저가 다시 보내드릴 예정입니다. <br>잠시만 기다려 주세요.',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 0) {
                            this.$emit('close')
                        }
                    })
                return
            }

            if (!this.likeActivate) {
                this.$modal
                    .basic({
                        body:
                            '이미 수락한 회원입니다. 상대방도 수락하면 약속 일정을 보내드립니다. (상대방이 수락하지 않는 경우 별도 연락을 드리진 않습니다.)',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                    .then(idx => {
                        if (idx === 0) {
                            this.$emit('close')
                        }
                    })
            } else {
                // 매칭권 과금 포인트 추가 2025.02.23
                // console.log("needExtraChargingPoint", this.needExtraChargingPoint)
                if (this.needExtraChargingPoint) {
                    // console.log("check needExtraChargingPoint")

                    if (
                        this.user.photo_histories.is_michagam ||
                        (this.user.source_photo_histories && this.user.source_photo_histories.is_manner) ||
                        this.userRatePlans.onGoingTicketPlan[0]
                    ) {
                        // console.log("pass needExtraChargingPoint")
                    } else {
                        // console.log("check2 needExtraChargingPoint")
                        this.$modal
                            .basic({
                                body: `매칭권이 없습니다. 매칭권을 구매하면 횟수 제한 없이 마음에 드는 이성의 프로필을 수락할 수 있고, 실제 약속까지 잡아드려요:)<br/>특가 할인 중이니 혜택을 놓치지 마세요!`,
                                buttons: [
                                    {
                                        // label: 'CONFIRM',
                                        label: '할인가 보기',
                                        class: 'btn-primary',
                                    },
                                ],
                            })
                            .then(idx => {
                                if (idx === 0) {
                                    this.$stackRouter.pop()
                                    this.$router.push({
                                        name: 'PrePaymentPage',
                                    })
                                }
                            })
                        return
                    }
                }

                let msg = ''
                let idx = 0

                if (!this.$store.getters.me.agree_penalty_policy) {
                    msg = await this.$modal.custom({
                        component: 'ModalAgreeLike',
                        options: {
                            type: 'after',
                        },
                    })
                } else {
                    idx = await this.$modal.basic({
                        title: '만남 의향이 있는 경우 수락해주세요 :)',
                        body: `${
                            this.user.photo_histories.is_michagam ||
                            (this.user.source_photo_histories && this.user.source_photo_histories.is_manner) ||
                            (this.userRatePlans.onGoingTicketPlan[0] &&
                                this.userRatePlans.onGoingTicketPlan[0].code.includes('unlimited'))
                                ? '나와 상대방이 서로 수락하면 약속 잡기가 시작되며, ' +
                                  `<span class="c-red">무료로 약속을 진행하는 경우에도</span>` +
                                  ' 약속 잡기가 시작 된 후 약속 잡기를 그만두면 약속 미진행 수수료 2만원, 약속이 잡힌 후 취소할 경우 취소 수수료 5만원이 부과됩니다.'
                                : '나와 상대방이 서로 수락하면 약속 잡기가 시작되며, 약속 잡기가 시작 된 후 약속 잡기를 그만두면 약속 미진행 수수료 2만원, 약속이 잡힌 후 취소할 경우 취소 수수료 5만원이 부과됩니다.'
                        }`,
                        buttons: [
                            {
                                label: 'CANCEL',
                                class: 'btn-default',
                            },
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                }

                if (msg === 'agree' || (this.$store.getters.me.agree_penalty_policy && idx === 1)) {
                    const res = await this.$modal.custom({
                        component: 'ModalSendLike',
                        options: {
                            type: 'personalStory',
                            userPersonalStories: this.userPersonalStories[this.index],
                            target: this.user,
                            message: this.message,
                        },
                    })
                    if (res === 'sentLike') {
                        if (this.user.source_photo_histories && this.user.source_photo_histories.accepted) {
                            await this.initSchedule()
                        }

                        const ret = await this.$modal.custom({
                            component: 'ModalSelectReason',
                            options: {
                                chat: this.$store.getters.chat,
                                status: 'accepted',
                                profilePhotoMessage: this.message,
                            },
                        })
                        console.log('ret', ret)

                        if (ret === 'savedReasons') {
                            this.$modal.custom({
                                component: 'ModalAppearanceScore',
                                options: {
                                    chat: this.$store.getters.chat,
                                    user: this.user,
                                    userPhotos: this.allPhotos,
                                },
                            })
                        }
                    } else if (res === 'moveInAppStorePage') {
                        this.$stackRouter.push({ name: 'InAppStorePage' })
                    }
                    this.init()
                }
            }
        },
    },
}
</script>
<style scoped lang="scss">
.title-box {
    padding-top: 20px;
    margin: 0 20px 0px 20px;
}

.answer-box {
    padding-bottom: 100px;
    margin: 12px 20px 0px 20px;
}

.name-age {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    padding: 16px 0;
}
</style>
