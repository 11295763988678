var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"product-bill m-b-30"},[_c('div',{staticClass:"title m-b-8 m-t-40"},[_vm._v("결제 금액")]),_c('div',{staticClass:"price-detail"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"key"},[_vm._v("정상가")]),_c('div',{staticClass:"value",domProps:{"innerHTML":_vm._s(`${_vm.price.originalPrice} 원`)}})]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"key"},[_vm._v(_vm._s(_vm.$moment().format('M'))+"월 이벤트 할인")]),_c('div',{staticClass:"value",domProps:{"innerHTML":_vm._s(`-${_vm.price.eventDiscount} 원`)}})]),(_vm.selectedCoupon)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"key"},[_vm._v("쿠폰 할인")]),_c('div',{staticClass:"value",domProps:{"innerHTML":_vm._s(`-${_vm.price.couponDiscount} 원`)}})]):_vm._e(),(_vm.selectedCoupon)?_c('div',{staticClass:"row upper-line padding-top"},[_c('div',{staticClass:"key"},[_vm._v("할인가")]),_c('div',{staticClass:"value",domProps:{"innerHTML":_vm._s(
                    `${(
                        parseInt(_vm.price.originalPrice.replaceAll(',', '')) -
                        parseInt(_vm.price.eventDiscount.replaceAll(',', '')) -
                        parseInt(_vm.price.couponDiscount.replaceAll(',', ''))
                    ).toLocaleString()} 원`
                )}})]):_c('div',{staticClass:"row upper-line padding-top"},[_c('div',{staticClass:"key"},[_vm._v("할인가")]),_c('div',{staticClass:"value",domProps:{"innerHTML":_vm._s(
                    `${(
                        parseInt(_vm.price.originalPrice.replaceAll(',', '')) -
                        parseInt(_vm.price.eventDiscount.replaceAll(',', ''))
                    ).toLocaleString()} 원`
                )}})]),_c('div',{staticClass:"row under-line"},[_c('div',{staticClass:"key"},[_c('span',[_vm._v("부가세(10%)")]),_c('i',{staticClass:"material-icons-outlined",on:{"click":function($event){_vm.mouseOver = !_vm.mouseOver}}},[_vm._v("info")])]),_c('div',{staticClass:"value",domProps:{"innerHTML":_vm._s(`${_vm.price.tax} 원`)}}),(_vm.mouseOver)?_c('div',{staticClass:"info"},[_c('p',{staticClass:"spoqa-f-bold"},[_vm._v("부가세 계산법")]),_c('p',[_vm._v("(상품 금액-할인액)*10%")])]):_vm._e()]),_c('div',{staticClass:"row m-t-8"},[_c('div',{staticClass:"key c-black"},[_vm._v("최종 결제금액")]),_c('div',{staticClass:"value"},[_c('span',{staticClass:"c-primary spoqa-f-bold"},[_vm._v(" "+_vm._s(_vm.price.sellingPrice)+" ")]),_c('span',[_vm._v("원")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }