<template>
    <div class="manager-introduction">
        <div class="manager-section basic">
            <div class="title-box">
                <p class="title-text">베이직 메니저</p>
                <p class="title-desc">월 1~2회 이상 문제 없이 매칭되고 있는 분에게 추천</p>
            </div>
            <div class="flex-box">
                <i class="material-icons">check_circle</i>
                <span>AI 추천 기반 프로필 제공</span>
            </div>

            <div class="flex-box">
                <i class="material-icons">check_circle</i>
                <span>평가가 누적될수록 정확도 향상</span>
            </div>

            <div class="cta-box">
                <div class="cta-header">
                    <p class="cta-title">무료</p>
                </div>
                <button class="cta-btn basic f-14">현재 이용중</button>
            </div>
        </div>

        <div class="manager-section professional">
            <div class="title-box">
                <p class="title-text">프로페셔널 메니저</p>
                <p class="title-desc">프로필이 마음에 들지 않거나, 원하는 만큼 매칭이 안 되는 분에게 추천</p>
            </div>

            <div class="flex-box">
                <i class="material-icons">check_circle</i>
                <span>전문 매니저 1:1 관리</span>
            </div>
            <div class="flex-box">
                <i class="material-icons">check_circle</i>
                <span>쌍방향 소통하며 매칭 진행</span>
            </div>
            <div class="flex-box">
                <i class="material-icons">check_circle</i>
                <span>디테일한 선호 사항 반영</span>
            </div>
            <div class="flex-box">
                <i class="material-icons">check_circle</i>
                <span>수락/거절 반영한 프로필 추천</span>
            </div>
            <div class="flex-box">
                <i class="material-icons">check_circle</i>
                <span>프로필 피드백 제공</span>
            </div>
            <div class="flex-box">
                <i class="material-icons">check_circle</i>
                <span>상세 프로필 조회 기능 무료 제공</span>
            </div>
            <div class="flex-box">
                <i class="material-icons">check_circle</i>
                <span>월 평균 2.7회 매칭</span>
            </div>

            <div class="cta-box">
                <div class="cta-header">
                    <span class="cta-title"> 2.9 </span>
                    <span class="cta-title-sub">만원/월</span>

                    <p class="cta-text">*부가세 별도</p>
                </div>
                <button class="cta-btn professional f-14" @click="upgradeManger()">업그레이드 하기</button>
            </div>
        </div>

        <!-- <div class="cs-icon" style="z-index: 10; right: 5%; bottom: 15%; position: absolute">
            <img @click="openCScenter()" class="like" :src="csIcon" alt="cs-icon" width="86px" height="86px" />
        </div> -->
        <div class="cs-center-btn" style="z-index: 10; right: 5%; bottom: 5%; position: absolute">
            <img
                @click="openCScenter()"
                class="like"
                :src="bubbleChatIcon"
                alt="chat-icon"
                width="25px"
                height="25px"
            />
            <div class="cs-text">문의하기</div>
        </div>
    </div>
</template>

<script>
import inquiryService from '@/services/inquiry'

export default {
    name: 'ManagerIntroductionPage',

    computed: {
        me() {
            return this.$store.getters.me
        },
        profile() {
            return (this.me || {}).profile
        },
        products() {
            return this.$store.getters.products
        },
        csIcon() {
            return require(`@/assets/images/cs/cs_center_icon.png`)
        },
        bubbleChatIcon() {
            return require(`@/assets/images/icons/bubble_chat.png`)
        },
    },
    methods: {
        async upgradeManger() {
            if (
                this.profile.marry === 1 ||
                !['서울', '경기', '인천'].find(reg => this.profile.region_name.indexOf(reg) !== -1)
            ) {
                return this.$alert(
                    '현재 수도권 지역, 초혼 회원들을 대상으로 시범 서비스 중입니다. 서비스 정식 오픈 시 안내드리도록 하겠습니다. 대단히 죄송합니다.'
                )
            }
            if (this.me.profile.status !== 'confirmed') {
                this.$modal.basic({
                    body: '서류인증이 완료된 후 구매할 수 있습니다.',
                    buttons: [
                        {
                            label: 'CONFIRM',
                            class: 'btn-primary',
                        },
                    ],
                })
                return
            }
            // console.log("do purchase", this.products)

            const selectedProduct = this.products.subscription_plan.find(pr => pr.code === 'pro_manager')

            if (!selectedProduct) {
                return this.$alert('상품 정보를 가져오지 못 했습니다. 잠시후 다시 시도해 주세요.')
            }
            // console.log("do purchase 2", selectedProduct)

            try {
                this.$store.commit('setSelectedProduct', selectedProduct)
                this.$store.commit('setSelectedPlan', selectedProduct)
                window.localStorage.setItem('selectedMembership', 'pro_manager')
                const payload = {
                    user_id: this.$store.getters.me.id,
                    category: 'manager',
                    funnel: 'enter_payment',
                }
                await inquiryService.updateFunnel(payload)

                this.$router.push({ name: 'PaymentMethodsPage', params: { categoryName: selectedProduct } })
            } catch (e) {
                this.$toast.error(e)
                console.log(e)
            }
        },
        async openCScenter() {
            const payload = {
                user_id: this.me.id,
                category: 'manager',
                funnel: 'enter_inquiry',
            }
            await inquiryService.updateFunnel(payload)

            this.$modal.custom({
                component: 'ModalInquiryWriter',
                options: {
                    product: 'pro_manager',
                },
            })
        },
    },
}
</script>

<style scoped>
.manager-introduction {
    padding: 20px;
}
.manager-section {
    padding: 20px 15px;
    margin-bottom: 30px;
    background-color: white;
    border-radius: 8px;
}
.manager-section.basic {
    border: 1px solid #ececec;
}
.manager-section.professional {
    border: 1px solid black;
}
.title-box {
    margin-bottom: 20px;
}
.title-text {
    font-family: Pretendard-Bold;
    font-size: 16px;
    font-weight: bold;
    color: rgba(21, 19, 96, 1);
    margin-bottom: 8px;
}
.title-desc {
    font-size: 12px;
    font-weight: medium;
    color: #7c7c7c;
}
.flex-box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    font-size: 12px;
    font-weight: medium;
    /* color: #7c7c7c; */
    color: #000000;

    .material-icons {
        margin-right: 5px;
        width: 13px;
        height: 13px;
        font-size: 13px;
    }
}
.cta-box {
    margin-top: 40px;

    .cta-header {
        margin-bottom: 10px;

        .cta-title {
            font-size: 20px;
            font-family: Pretendard-Bold;
            font-weight: semibold;
            color: rgba(21, 19, 96, 1);
        }
        .cta-title-sub {
            font-size: 12px;
            font-family: Pretendard-Bold;
            font-weight: semibold;
            color: rgba(21, 19, 96, 1);
        }
        .cta-text {
            margin-top: 1px;
            font-size: 9px;
            font-family: Pretendard-Regular;
            font-weight: normal;
            color: #5c5c77;
        }
    }
    .cta-btn {
        width: 100%;
        padding: 12px 0;
        border-width: 0;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;

        &.basic {
            background-color: #b3b3b3;
            color: #f7f7f4;
        }

        &.professional {
            background-color: rgba(21, 19, 96, 1);
            color: #f7f7f4;
        }
    }
}

.cs-center-btn {
    width: 56px;
    height: 56px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 100px;
    border-width: 0;
    fill: #fff;
    filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.25));
    .cs-text {
        margin-top: 3px;
        color: var(--Purple-Grey, #5c5c77);
        font-family: Inter;
        font-size: 8px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
</style>
