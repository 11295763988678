import { render, staticRenderFns } from "./UserPersonalStories.vue?vue&type=template&id=681fa852&scoped=true"
import script from "./UserPersonalStories.vue?vue&type=script&lang=js"
export * from "./UserPersonalStories.vue?vue&type=script&lang=js"
import style0 from "./UserPersonalStories.vue?vue&type=style&index=0&id=681fa852&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "681fa852",
  null
  
)

export default component.exports