<template>
    <div class="invitation-friend" @scroll="onScroll">
        <div class="top-section">
            <div class="head">
                <div class="headline">
                    <p class="f-20">내 적립금</p>
                    <p class="my-mileage f-24 f-bold m-t-13">{{ myMileage }}원</p>
                </div>
            </div>
        </div>
        <div class="content-section">
            <div class="title f-17 f-bold">적립금 얻기</div>
            <div class="mileage-item" :key="item.id" v-for="item in itemList" @click="onClickItem(item)">
                <div class="items f-15">
                    <div class="item-content bg-white">
                        <span>{{ item.name }}</span>
                        <div class="right">
                            <img class="img-detail m-r-6" :src="item.imgSrc" alt="" />
                            <span class="price f-bold m-r-15">{{ item.price }}</span>
                            <i class="material-icons">chevron_right</i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-section m-t-20 m-b-20">
            <div class="title f-17 f-bold">적립금 사용하기</div>
            <div class="mileage-item" :key="item.id" v-for="item in purchaseList" @click="onClickUseItem(item)">
                <div class="items f-15">
                    <div class="item-content bg-white">
                        <span>{{ item.name }}</span>
                        <div class="right">
                            <i class="material-icons">chevron_right</i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import mileageService from '@/services/mileage'

export default {
    name: 'MileagePage',
    components: {},
    data: () => ({
        selectedProduct: null,
        invitationCode: null,
        invitationPoint: 0,
        invitationCount: 0,
        hasEnteredInvitationCode: true,
        showHeaderTitle: false,
    }),
    computed: {
        me() {
            return this.$store.getters.me
        },
        myMileage() {
            return this.me ? this.me.mileage : 0
        },
        itemList() {
            const itemList = [
                {
                    id: 1,
                    name: '신규 초대하기(추천코드)',
                    price: '7만원',
                    component: 'InvitationFriendPage',
                    imgSrc: require('@/assets/images/icons/twemoji_coin.png'),
                },
                {
                    id: 2,
                    name: '소득 인증하기',
                    price: '5천원',
                    component: 'CertificatePage',
                    imgSrc: require('@/assets/images/icons/twemoji_coin.png'),
                },
                {
                    id: 3,
                    name: '블라인드 후기쓰기',
                    price: '3만원',
                    component: 'BlindReviewsPage',
                    imgSrc: require('@/assets/images/icons/twemoji_coin.png'),
                },
                {
                    id: 4,
                    name: '블라인드 댓글쓰기',
                    price: '1만원',
                    component: 'BlindRepliesPage',
                    imgSrc: require('@/assets/images/icons/twemoji_coin.png'),
                },
            ]

            return itemList
        },
        purchaseList() {
            const itemList = [
                {
                    id: 1,
                    name: '매칭권 할인받기',
                },
                {
                    id: 2,
                    name: '멤버십 할인받기',
                },
                {
                    id: 3,
                    name: '포인트로 교환하기',
                },
            ]

            return itemList
        },
    },
    created() {
        // this.init()
    },
    beforeDestroy() {},
    methods: {
        onClickItem(item) {
            if (item.component === 'CertificatePage') {
                this.$stackRouter.push({ name: item.component })
            } else {
                this.$router.push({ name: item.component })
            }
        },
        async onClickUseItem(item) {
            try {
                if (item.name === '매칭권 할인받기') {
                    this.$modal.basic({
                        body: '매칭권 구매 페이지에서 적립금 사용이 가능합니다.',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'c-primary',
                            },
                        ],
                    })

                    return
                } else if (item.name === '멤버십 할인받기') {
                    this.$modal.basic({
                        body: '구매 페이지에서 적립금 사용이 가능합니다.',
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'c-primary',
                            },
                        ],
                    })

                    return
                } else {
                    // 포인트로 교환하기
                    if (this.myMileage < 5000) {
                        this.$modal.basic({
                            body: '적립금 5천원 이상부터 교환이 가능합니다.',
                            buttons: [
                                {
                                    label: 'CONFIRM',
                                    class: 'c-primary',
                                },
                            ],
                        })

                        return
                    } else {
                        const idx = await this.$modal.basic({
                            body: '적립금 5천원을 사용해 50 포인트를 얻습니다.',
                            buttons: [
                                {
                                    label: 'CANCEL',
                                    class: 'btn-default',
                                },
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                        console.log(idx)

                        if (idx === 1) {
                            this.exchangePoint()
                        }
                    }
                }
            } catch (e) {
                this.$loading(false)
                console.error(e)
            }
        },
        async exchangePoint() {
            try {
                this.$loading(true)
                const res = await mileageService.exchangePoint({ user_id: this.me.id })
                this.$loading(false)
                if (res.res === 'success') {
                    this.$toast.success(res.msg)
                }
            } catch (e) {
                this.$loading(false)
                this.$toast.error(e.data.msg)
            }
        },
        onScroll(event) {
            if (event.target.scrollTop >= 52) {
                this.showHeaderTitle = true
            } else {
                this.showHeaderTitle = false
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.invitation-friend {
    height: 100%;
    display: flex;
    flex-direction: column;
    .top-section {
        // background: #ffffff;
        position: relative;
        top: 0;
    }
    .top-section {
        .head {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: auto 100%;
            width: 100%;
            // height: 130px;
            padding: 20px;

            @media (max-width: 299px) {
                padding: 10px 17px 4px;
                height: 110px;
            }

            @media (min-width: 300px) and (max-width: 389px) {
                padding: 20px 17px 4px;
                height: 130px;
            }

            .head-btn {
                width: fit-content;
                border-radius: 100px;
                background-color: #f4faff;
                padding: 2px 8px;
                font-size: 12px;
                margin: 0 auto;
                color: $blue-skymarry;
            }
            .headline {
                border-radius: 8px;
                font-family: Pretendard;
                background-color: #faf0e1;
                color: black;
                text-align: center;
                // margin-top: 14px;
                padding: 26px 0 16px 0;

                .my-mileage {
                    line-height: 160%;
                }
            }
        }
    }
    .content-section {
        .title {
            margin-left: 20px;
        }
        margin-top: 14px;
        .items {
            margin: 14px 20px;
            position: relative;

            .item-content {
                padding: 18px 14px;
                position: relative;
                border-radius: 8px;

                .right {
                    position: absolute;
                    display: flex;
                    right: 14px;
                    top: 50%;
                    transform: translateY(-50%);

                    .img-detail {
                        width: 24px;
                    }

                    .price {
                        @include center;
                    }
                }
            }
        }
    }
    // .bottom-border {
    //     border-bottom: 1px solid $grey-02;
    // }
}
</style>
