var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"complete-subs-payment"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"close-icon",on:{"click":function($event){return _vm.onClickClose()}}},[_c('i',{staticClass:"material-icons i-right f-24",on:{"click":function($event){return _vm.onClickClose()}}},[_vm._v("close")])])]),(this.product.code !== 'pro_manager')?_c('main',{staticClass:"main"},[_c('div',{staticClass:"main-title"},[_c('div',{staticClass:"f-24 f-bold m-t-20"},[_vm._v(" "+_vm._s(_vm.$translate(_vm.product.code.toUpperCase()))+" 멤버십이 "),_c('br'),_vm._v(" 시작되었습니다! ")])]),_vm._m(0),_vm._m(1),_vm._m(2)]):_c('main',{staticClass:"main"},[_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-info m-b-40 m-t-40"},[_c('div',{staticClass:"title f-16 f-bold"},[_vm._v("1. 지금부터 모든 프로필 열람이 가능해요!")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"key dotted"},[_vm._v("*")]),_c('div',{staticClass:"f-14"},[_vm._v("세부 지역, 경제력, 학력 등 모든 프로필이 열람 가능합니다.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-info m-b-40"},[_c('div',{staticClass:"title f-16 f-bold"},[_vm._v("2. Marry Fit 멤버십 회원 전용 매칭 선호사항을 입력해주세요.")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"key dotted"},[_vm._v("*")]),_c('div',{staticClass:"f-14"},[_vm._v(" 최대 7가지 필터와 우선순위 설정이 가능해요. ( ‘마이페이지’와 매니저 대화방에서 입력 가능) ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-info m-b-40"},[_c('div',{staticClass:"title f-16 f-bold"},[_vm._v("3. 곧 새로운 매니저를 배정해드릴 예정입니다.")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"key dotted"},[_vm._v("*")]),_c('div',{staticClass:"f-14"},[_vm._v("회원님을 위한 전문 매니저를 새롭게 배정해드릴 예정이에요!")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"key dotted"},[_vm._v("*")]),_c('div',{staticClass:"f-14"},[_vm._v(" 기존 회원님의 매칭 기록은 모두 새로운 매니저에게 인수인계 되오니 걱정하지 않으셔도 좋습니다. ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"key dotted"},[_vm._v("*")]),_c('div',{staticClass:"f-14"},[_vm._v("매니저 배정에는 1~2 영업일이 소요됩니다.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-title"},[_c('div',{staticClass:"f-24 f-bold m-t-20"},[_vm._v(" 프로 매니저를 "),_c('br'),_vm._v(" 구독해주셔서 감사해요! ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-info m-b-40 m-t-40"},[_c('div',{staticClass:"title f-16 f-bold"},[_vm._v("1. 지금부터 모든 프로필 열람이 가능해요!")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"key dotted"},[_vm._v("*")]),_c('div',{staticClass:"f-14"},[_vm._v("세부 지역, 경제력, 학력 등 모든 프로필이 열람 가능합니다.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-info m-b-40"},[_c('div',{staticClass:"title f-16 f-bold"},[_vm._v("2. 매칭 선호사항을 입력해주세요.")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"key dotted"},[_vm._v("*")]),_c('div',{staticClass:"f-14"},[_vm._v(" 매니저가 회원님이 설정한 내용을 참고해 매칭을 진행합니다. ('마이페이지 > 매칭 선호사항'에서 설정 가능) ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-info m-b-40"},[_c('div',{staticClass:"title f-16 f-bold"},[_vm._v("3. 곧 새로운 매니저를 배정해드릴 예정입니다.")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"key dotted"},[_vm._v("*")]),_c('div',{staticClass:"f-14"},[_vm._v("회원님의 니즈에 맞는 매니저를 새롭게 배정해드릴 예정이에요!")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"key dotted"},[_vm._v("*")]),_c('div',{staticClass:"f-14"},[_vm._v("지난 매칭 기록은 새 매니저에게 인수인계 됩니다.")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"key dotted"},[_vm._v("*")]),_c('div',{staticClass:"f-14"},[_vm._v("매니저 배정에는 1~2영업일이 소요됩니다.")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"key dotted"},[_vm._v("*")]),_c('div',{staticClass:"f-14"},[_vm._v("프로 매니저 구독 기간은 매니저 배정일부터 시작됩니다.")])])])])
}]

export { render, staticRenderFns }