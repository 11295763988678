import { render, staticRenderFns } from "./HeaderBar.vue?vue&type=template&id=48a4f0de&scoped=true"
import script from "./HeaderBar.vue?vue&type=script&lang=js"
export * from "./HeaderBar.vue?vue&type=script&lang=js"
import style0 from "./HeaderBar.vue?vue&type=style&index=0&id=48a4f0de&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48a4f0de",
  null
  
)

export default component.exports