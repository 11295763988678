<template>
    <div class="floating-components bg-main">
        <template>
            <div class="instant-room" v-if="isInstantChat">
                <InstantUser />
            </div>
            <SpecialFeats
                v-if="!isInstantChat && planDetail.name.includes('Love Fit') && agentName === '퍼스널 매칭매니저'"
                :chat="chat"
            />
            <!-- <ChatExtendBanner
                v-if="showBanner === 'extend' && chat.agent_id"
                @move-to-productlist="moveToProductList"
            /> -->
            <!-- <ChatRepurchaseBanner
                v-if="showBanner === 'repurchase' && chat.agent_id"
                @move-to-productlist="moveToProductList"
            /> -->
            <UserChatFloating v-if="chat.schedule" @move-to-productlist="moveToProductList" />

            <!-- 매니저 변경 유도 -->
            <div
                v-if="agentId === 18 && isUsingManager === 'manager_active' && planDetailCode !== 'pro_manager'"
                class="manager-box"
            >
                <div class="special-feats p-5">
                    <div class="feat m-r-4">
                        <div class="container">
                            <div>매칭이 만족스럽지 않다면?</div>
                            <div class="upgrade-container">
                                <button class="upgrade-btn-real" @click="openManagerPage()">매니저 변경하기</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import InstantUser from './InstantUser'
import UserChatFloating from '@/routes/chat/chatroom/components/floating-components/UserChatFloating'
import SpecialFeats from '@/routes/chat/chatroom/components/floating-components/SpecialFeats.vue'
// import ChatExtendBanner from '@/routes/chat/chatroom/components/floating-components/ChatExtendBanner'
// import ChatRepurchaseBanner from '@/routes/chat/chatroom/components/floating-components/ChatRepurchaseBanner'
import { getPlanNameById } from '@/store/data/product'
import inquiryService from '@/services/inquiry'

export default {
    name: 'FloatingComponents',
    components: {
        InstantUser,
        SpecialFeats,
        UserChatFloating,
        // ChatExtendBanner,
        // ChatRepurchaseBanner,
    },
    props: {
        chat: {
            type: Object,
            required: true,
        },
    },
    computed: {
        me() {
            return this.$store.getters.me || {}
        },
        isPrivacy() {
            if (!this.$store.getters.me) return false

            return this.$isPrivacy()
        },
        isMale() {
            return this.$store.getters.me.gender === 0
        },
        agentName() {
            return this.chat.user.name
        },
        agentId() {
            return this.chat.agent_id
        },
        isInstantChat() {
            return this.$isInstantChat(this.chat)
        },
        userRatePlans() {
            return this.$store.getters.userRatePlans
        },
        myPlan() {
            return this.userRatePlans.onGoing.find(o => o.ptype === 'subscription_plan')
        },
        planDetail() {
            if (this.userRatePlans.onGoing.length > 0) {
                return getPlanNameById(this.myPlan.product_id)
            } else {
                return null
            }
        },
        planDetailCode() {
            if (!this.planDetail) {
                return null
            } else {
                if (this.planDetail.code.includes('pro_manager')) {
                    return 'pro_manager'
                } else if (this.planDetail.code.includes('mf_3month')) {
                    return 'mf_3month'
                } else if (this.planDetail.code.includes('mfplus_3month')) {
                    return 'mfplus_3month'
                } else {
                    return 'lovefit_eternal'
                }
            }
        },
        isUsingManager() {
            // legacy_membership => 기존 멤베십 구매자
            // manager_pending => 아직 매니저 선택 적용안된 대기자
            // manager_active => 매니저 선택 적용된자
            // console.log('isUsingManager:', this.me, this.profile, this.planDetailCode);
            if (!this.me.id) {
                return 'none'
            }
            if (['mf_3month', 'mfplus_3month'].indexOf(this.planDetailCode) !== -1) {
                return 'legacy_membership'
            } else if (this.me.id >= 19640 || this.planDetailCode === 'pro_manager') {
                return 'manager_active'
            } else if (this.me.id % 4 > 0) {
                return 'manager_pending'
            } else {
                return 'manager_active'
            }
        },
        validTickets() {
            return this.$store.getters.validTickets
        },
        showBanner() {
            if (!(this.userRatePlans.pending || {}).length) {
                if (!this.userRatePlans.onGoing) {
                    // 다시 시작
                    return 'repurchase'
                } else if (
                    this.$moment(this.userRatePlans.onGoing.valid_until).diff(this.$moment(), 'days') <= 7 &&
                    this.$moment(this.userRatePlans.onGoing.valid_until).diff(this.$moment(), 'days') >= 0
                ) {
                    // 갱신하기
                    // 기간제, 횟수제 분기
                    return 'extend'
                    // } else if (
                    //     getPlanNameById(this.userRatePlans.onGoing.product_id).amount >= 2 &&
                    //     this.validTickets.length === 1
                    // ) {
                    //     // 횟수제 1회 남았을 때 갱신하기
                    //     return 'extend'
                } else {
                    return false
                }
            } else {
                return false
            }
        },
    },
    methods: {
        onClickPrivacy() {
            this.$modal.custom({
                component: 'ModalAlertNoX',
                options: {
                    title: 'SUPER_PRIVACY_ON',
                    body: `회원님의 동의가 없으면 누구에게도 프로필이 공개되지 않습니다.`,
                },
            })
        },
        privacyRemainDay() {
            const privacy = this.$store.getters.me.products.filter(p => p.ptype === 'privacy' && p.enabled === true)
            if (privacy === null || privacy === []) return

            const validUntil = privacy[0].valid_until.split('T')[0]

            return this.$moment(validUntil).diff(this.$moment(), 'days')
        },
        moveToProductList() {
            this.$router.push({ name: 'PrePaymentPage' })
        },
        async openManagerPage() {
            const payload = {
                user_id: this.me.id,
                category: 'manager',
                funnel: 'enter_manager_list',
            }
            await inquiryService.updateFunnel(payload)
            this.$router.push({
                // name: item.route,
                name: 'ManagerIntroductionPage',
                params: { selectedMembership: this.planDetail.code },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.floating-components {
    background: white;
    position: absolute;
    //top: 42px;
    top: 0;
    left: 0;
    right: 0;
    padding-bottom: 2px;
    z-index: 3;
}

.special-feats {
    display: flex;
    color: black;
    position: absolute;
    width: 100%;
    background-color: #fff9ef;
    padding-right: 16px;

    &.secret {
        background: $grey-10;
        color: white !important;
    }

    .feat {
        width: 100%;
        padding: 11px 0;
        @include center;

        // &:nth-last-child(1) {
        //     .container {
        //         border-right: none;
        //     }
        // }

        .container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 16px;
            width: 100%;
            border-radius: 8px;
            margin: 0 20px;
            font-weight: 600;
            font-size: 13px;
            font-family: Pretendard-Medium;
            background: #5c5c77;
            color: #fff;

            .upgrade-container {
                // display: flow;
                //     min-width: 100px;
                max-width: 40%;
                @include center;
                .upgrade-btn {
                    // display: flex;
                    min-width: 97px;
                    max-width: 40%;
                }

                .upgrade-btn-real {
                    display: flex;
                    align-items: center;
                    justify-items: center;
                    border-radius: 24px;
                    font-size: 12px;
                    color: white;
                    font-family: Pretendard-Bold;
                    background-color: #f64f56;
                    padding: 8px 10px;
                    border-width: 0;
                }
            }
        }
    }
}
</style>
