<template>
    <div class="my-page-menu">
        <ul class="menu">
            <li
                class="item"
                v-for="(item, index) in upper"
                :key="item.key"
                @click="onClickMenu(item)"
                :class="{ 'last-item': index === upper.length - 1 }"
            >
                <img class="icons-outlined icon" v-if="item.icon" :src="item.icon" />
                <span
                    class="key"
                    v-if="isUsingManager === 'manager_active' && item.key === 'MY_MEMBERSHIP'"
                    v-html="'내 매니저'"
                />
                <span class="key" v-else v-html="$translate(item.key)" />
                <!-- <span class="key" v-html="$translate(item.key)" /> -->
                <div class="valid-plan flex-fill">
                    <div v-if="item.key === 'MY_MEMBERSHIP' && myPlan" class="valid-title f-14">
                        {{
                            isUsingManager == 'manager_active' ? manager_name : $translate(planDetailCode.toUpperCase())
                        }}
                        <span v-if="subscriptionValidDays > 0" class="valid m-l-5">
                            {{ subscriptionValidDays }}일 남음
                        </span>
                        <!-- <span v-else class="valid m-r-4 m-l-5"> 구매하기 </span> -->
                        <i class="material-icons i-right m-l-10">chevron_right</i>
                    </div>
                    <div v-else-if="item.key === 'MY_MEMBERSHIP' && !myPlan" class="valid-title f-14">
                        시작하기
                        <!-- <span v-if="unlimitedValidDays > 0" class="valid m-l-5"> {{ unlimitedValidDays }}일 남음 </span> -->
                        <!-- <span v-else class="valid m-r-4 m-l-5"> 구매하기 </span> -->
                        <i class="material-icons i-right m-l-10">chevron_right</i>
                    </div>
                    <div
                        v-else-if="item.key === 'TICKET' && unlimitedRatePlan && unlimitedRatePlan.length > 0"
                        class="valid-title"
                    >
                        기간제
                        <span v-if="unlimitedValidDays > 0" class="valid m-l-5"> {{ unlimitedValidDays }}일 남음 </span>
                        <i class="material-icons i-right m-l-10">chevron_right</i>
                    </div>
                    <div v-else-if="item.key === 'TICKET' && validTickets.length > 0" class="valid-title">
                        <!-- 잔여 횟수 <span class="valid m-l-4"> {{ validTickets.length }}회</span> -->
                        <span class="valid m-l-4"> {{ validTickets.length }}장 보유</span>
                        <i class="material-icons i-right m-l-10">chevron_right</i>
                    </div>
                    <div
                        v-else-if="
                            item.key === 'RATE_TICKET' && unlimitedRatePlan && unlimitedRatePlan.ptype === 'rate_plan'
                        "
                        class="valid-title"
                    >
                        <span v-if="unlimitedValidDays > 0" class="valid-title m-l-5"> {{ unlimitedValidDay }} </span>
                    </div>
                    <div v-else class="valid-title">
                        구매하기
                        <i class="material-icons i-right m-l-10">chevron_right</i>
                    </div>
                </div>
            </li>
            <div
                v-if="
                    (planDetail.length === 0 || !planDetail.name.includes('Marry Fit Plus')) &&
                    planDetailCode !== 'pro_manager'
                "
                class="button-wrapper"
            >
                <button class="button-membership f-16" @click="onClickUpgrade">
                    {{ isUsingManager == 'manager_active' ? '매니저 변경하기' : '멤버십 업그레이드하기' }}
                </button>
            </div>
        </ul>
    </div>
</template>

<script>
import { getPlanNameById } from '@/store/data/product'
import inquiryService from '@/services/inquiry'

export default {
    name: 'MyTicketMenu',
    props: {
        isUsingManager: {
            type: String,
            default: 'none',
        },
        manager_name: {
            type: String,
            default: '',
        },
        stepToast: {
            type: [Boolean, Object],
        },
    },
    computed: {
        isFemale() {
            return (this.$store.getters.me || {}).gender === 1
        },
        upper() {
            const items = [
                {
                    key: 'MY_MEMBERSHIP',
                    route: 'MembershipIntroductionPage',
                    show: true,
                    icon: require(`@/assets/images/my-membership.png`),
                },
                {
                    key: 'TICKET',
                    route: 'PrePaymentPage',
                    show: true,
                    icon: require(`@/assets/images/ticket.png`),
                },
                {
                    key: 'RATE_TICKET',
                    show: this.unlimitedRatePlan && this.unlimitedRatePlan.ptype === 'rate_plan',
                    icon: require(`@/assets/images/rateticket.png`),
                },
            ]
            return items.filter(item => item.show)
        },
        userRatePlans() {
            return this.$store.getters.userRatePlans || {}
        },
        myPlan() {
            const plan = this.userRatePlans.onGoing.find(o => o.ptype === 'subscription_plan')
            return plan
        },
        validTickets() {
            return this.$store.getters.validTickets.filter(item => item.enabled)
        },
        planDetail() {
            return this.myPlan ? getPlanNameById(this.myPlan.product_id) : []
        },
        planDetailCode() {
            if (!this.planDetail) {
                return null
            } else {
                if (this.planDetail.code.includes('pro_manager')) {
                    return 'pro_manager'
                } else if (this.planDetail.code.includes('mf_3month')) {
                    return 'mf_3month'
                } else if (this.planDetail.code.includes('mfplus_3month')) {
                    return 'mfplus_3month'
                } else {
                    return 'lovefit_eternal'
                }
            }
        },
        subscriptionValidDays() {
            if (!this.myPlan || (this.planDetail && this.planDetail.code.includes('lovefit'))) return null
            const validUntil = this.$moment(this.myPlan.valid_until).format('YYYY-MM-DD')
            return this.$moment(validUntil).diff(this.$moment().format('YYYY-MM-DD'), 'days')
        },
        unlimitedRatePlan() {
            if (this.userRatePlans.onGoingTicketPlan.length === 0) return {}
            const plan = this.userRatePlans.onGoingTicketPlan.find(o => o.code.includes('unlimited'))
            // const plan = this.$store.getters.products.all.find(
            //     item => item.id === unlimited.product_id
            // )
            if (plan) {
                return plan
            } else {
                return null
            }
        },
        unlimitedValidDays() {
            if (!this.unlimitedRatePlan) return {}
            const validUntil = this.$moment(this.unlimitedRatePlan.valid_until).format('YYYY-MM-DD')
            return this.$moment(validUntil).diff(this.$moment().format('YYYY-MM-DD'), 'days')
        },
        unlimitedValidDay() {
            if (!this.unlimitedRatePlan) return {}
            const ratePlanStart = this.$moment(this.unlimitedRatePlan.valid_from).format('YY.MM.DD')
            let ratePlanEnd = this.$moment(this.unlimitedRatePlan.valid_until)
            ratePlanEnd = ratePlanEnd.subtract(1, 'days').format('YY.MM.DD')
            return `${ratePlanStart} ~ ${ratePlanEnd}`
        },
    },
    methods: {
        async onClickMenu(item) {
            if (item.route === 'PrePaymentPage') {
                this.$router.push({ name: item.route })
                return
            }
            // if (this.stepToast && this.stepToast.step <= item.step) {
            //     this.$toast.success(this.stepToast.msg)
            //     return
            // }
            if (this.isUsingManager === 'manager_active') {
                const payload = {
                    user_id: this.$store.getters.me.id,
                    category: 'manager',
                    funnel: 'enter_manager_list',
                }
                await inquiryService.updateFunnel(payload)
                this.$router.push({
                    // name: item.route,
                    name: 'ManagerIntroductionPage',
                    params: { selectedMembership: this.planDetail.code },
                })
                return
            }
            if (item.key === 'MY_MEMBERSHIP') {
                const payload = {
                    user_id: this.$store.getters.me.id,
                    category: 'membership',
                    funnel: 'enter_membership_list',
                }
                await inquiryService.updateFunnel(payload)
            }
            this.$router.push({
                name: item.route,
                params: { selectedMembership: this.planDetail.code },
            })
        },
        async onClickUpgrade() {
            if (this.isUsingManager === 'manager_active') {
                const payload = {
                    user_id: this.$store.getters.me.id,
                    category: 'manager',
                    funnel: 'enter_manager_list',
                }
                await inquiryService.updateFunnel(payload)
                this.$router.push({
                    // name: item.route,
                    name: 'ManagerIntroductionPage',
                    params: { selectedMembership: this.planDetail.code },
                })
                return
            }
            const payload = {
                user_id: this.$store.getters.me.id,
                category: 'membership',
                funnel: 'enter_membership_list',
            }
            await inquiryService.updateFunnel(payload)
            this.$router.push({
                name: 'MembershipIntroductionPage',
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.my-page-menu {
    // .hr.thick {
    //     background: $grey-02;
    //     border: 0;
    //     height: 8px;
    // }
    background: #e8e4e7;

    .item {
        // height: 56px;
        padding: 20px 20px 0 20px;
        display: flex;
        align-items: center;
        position: relative;
        // &:nth-child(3) {
        //     padding-bottom: 20px;
        // }

        // border-top: solid 1px #dcdcdc;

        .icon {
            width: 24px;
            margin-right: 12px;
        }

        .valid-plan {
            position: absolute;
            right: 20px;

            .valid-title {
                display: flex;
                align-items: center;
                font-weight: 400;
                color: #5c5c77;

                .valid {
                    font-weight: 500;
                    color: #151360;
                }
            }
        }

        .key {
            font-size: 14px;
            color: black;
        }
        // &:nth-child(1) {
        //     border-bottom: solid 8px rgb(247, 248, 249);
        // }
    }

    .last-item {
        padding-bottom: 20px;
    }

    .button-wrapper {
        width: 100%;
        padding: 0px 20px 20px 20px;
        .button-membership {
            color: white;
            background: #151360;
            border-radius: 8px;
            padding: 12px 10px;
            width: 100%;
            font-weight: 600;
        }
    }
}
</style>
