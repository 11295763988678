import Vue from 'vue'
import Router from 'vue-router'
import { store as $store } from '@/store'

import OnboardingPage from '@/routes/onboarding/OnBoardingPage'
import OnBoardWelcomePage from '@/routes/onboarding/components/OnBoardWelcomePage'
import FrontPage from '@/routes/FrontPage'
import LoginPage from '@/routes/LoginPage'
import MyPage from '@/routes/my-page/MyPage'
import NotificationsPage from '@/routes/notifications/NotificationsPage'
import ChatsPage from '@/routes/chat/chats/ChatsPage.vue'
import ChatroomPage from '@/routes/chat/chatroom/ChatroomPage'
import UserDetailPage from '@/routes/user-detail/UserDetailPage'
import NotFoundPage from '@/routes/NotFoundPage'
import ProfileUpdateRequestPage from '@/routes/profile-update-request/ProfileUpdateRequestPage'
import FindEmailPage from '@/routes/FindEmailPage'
import FindPasswordPage from '@/routes/FindPasswordPage'
import ResetPasswordPage from '@/routes/ResetPasswordPage'
import NPSMainPage from '@/routes/nps/NPSMainPage'
import NPSFeedbackPage from '@/routes/nps/NPSFeedbackPage'
import NTSMainPage from '@/routes/nts/NTSMainPage'
import NTSFeedbackPage from '@/routes/nts/NTSFeedbackPage'
import HomePage from '@/routes/HomePage'
import DormantPage from '@/routes/setting/DormantPage'
import CommunicationPage from '@/routes/communication/CommunicationPage'
import InvitationFriendPage from '@/routes/invitationFriend/InvitationFriendPage'
import BlindRepliesPage from '@/routes/invitationFriend/BlindRepliesPage'
import BlindReviewsPage from '@/routes/invitationFriend/BlindReviewsPage'
import MileagePage from '@/routes/invitationFriend/MileagePage'
import SchedulePage from '@/routes/schedule/SchedulePage'
import ScheduleListPage from '@/routes/schedule/ScheduleListPage'
import ScheduleCancelPage from '@/routes/ScheduleCancelPage'
import WaiveRequestPage from '@/routes/schedule/WaiveRequestPage'
import WaiveSubmitPage from '@/routes/schedule/WaiveSubmitPage'
import WaitlistPage from '@/routes/WaitlistPage'
import PrePaymentPage from '@/routes/prepay/PrePaymentPage'
import MembershipIntroductionPage from '@/routes/membership/MembershipIntroductionPage'
import ManagerIntroductionPage from '@/routes/membership/ManagerIntroductionPage'
// import PrePaymentCategoryPage from '@/routes/prepay/PrePaymentCategoryPage'
import PaymentMethodsPage from '@/routes/prepay/PaymentMethodsPage'
import PaymentCancelFeePage from '@/routes/prepay/PaymentCancelFeePage'
import TicketPaymentMethodsPage from '@/routes/prepay/TicketPaymentMethodsPage'
import PayByAccountPage from '@/routes/prepay/PayByAccountPage'
import AccountPayMethodPage from '@/routes/prepay/AccountPayMethodPage'
import CompletePaymentPage from '@/routes/prepay/CompletePaymentPage'
import PaymentLoadingPage from '@/routes/prepay/PaymentLoadingPage'
import MembershipDetailPage from '@/routes/membership/MembershipDetailPage'
import CompleteMembershipPaymentPage from '@/routes/membership/CompleteMembershipPaymentPage'
import ModalFeedbackEvent from '@/routes/ModalFeedbackEvent'
import CertificatePage from '@/routes/my-page/CertificatePage'
import SuperPrivacyIntroductionPage from '@/routes/super-privacy-introduction/SuperPrivacyIntroductionPage'

import signupRoutes from './signup'

Vue.use(Router)

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage,
    },
    {
        path: '/not-found',
        name: 'NotFoundPage',
        component: NotFoundPage,
    },
    {
        path: '/front',
        name: 'FrontPage',
        component: FrontPage,
        // props: true,
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: LoginPage,
    },
    {
        path: '/my-page',
        name: 'MyPage',
        component: MyPage,
    },
    {
        path: '/chats',
        name: 'ChatsPage',
        component: ChatsPage,
    },
    {
        path: '/chatroom',
        name: 'ChatroomPage',
        component: ChatroomPage,
    },
    {
        path: '/user-detail',
        name: 'UserDetailPage',
        component: UserDetailPage,
    },
    {
        path: '/notifications',
        name: 'NotificationsPage',
        component: NotificationsPage,
    },
    {
        path: '/profile-update-request',
        name: 'ProfileUpdateRequestPage',
        component: ProfileUpdateRequestPage,
    },
    {
        path: '/find-email',
        name: 'FindEmailPage',
        component: FindEmailPage,
    },
    {
        path: '/find-password',
        name: 'FindPasswordPage',
        component: FindPasswordPage,
    },
    {
        path: '/reset-password',
        name: 'ResetPasswordPage',
        component: ResetPasswordPage,
    },
    {
        path: '/nps-main',
        name: 'NPSMainPage',
        component: NPSMainPage,
    },
    {
        path: '/nps-feedback',
        name: 'NPSFeedbackPage',
        component: NPSFeedbackPage,
    },
    {
        path: '/nts-main',
        name: 'NTSMainPage',
        component: NTSMainPage,
    },
    {
        path: '/nts-feedback',
        name: 'NTSFeedbackPage',
        component: NTSFeedbackPage,
    },
    {
        path: '/dormant',
        name: 'DormantPage',
        component: DormantPage,
    },
    {
        path: '/communication',
        name: 'CommunicationPage',
        component: CommunicationPage,
    },
    {
        path: '/schedule',
        name: 'SchedulePage',
        component: SchedulePage,
    },
    {
        path: '/schedule-list',
        name: 'ScheduleListPage',
        component: ScheduleListPage,
    },
    {
        path: '/schedule-cancel',
        name: 'ScheduleCancelPage',
        component: ScheduleCancelPage,
    },
    {
        path: '/waive-request-page',
        name: 'WaiveRequestPage',
        component: WaiveRequestPage,
    },
    {
        path: '/waive-submin-page',
        name: 'WaiveSubmitPage',
        component: WaiveSubmitPage,
    },
    {
        path: '/invitation',
        name: 'InvitationFriendPage',
        component: InvitationFriendPage,
    },
    {
        path: '/blind-replies',
        name: 'BlindRepliesPage',
        component: BlindRepliesPage,
    },
    {
        path: '/blind-reviews',
        name: 'BlindReviewsPage',
        component: BlindReviewsPage,
    },
    {
        path: '/mileage',
        name: 'MileagePage',
        component: MileagePage,
    },
    {
        path: '/onboard-intro',
        name: 'OnBoardingPage',
        component: OnboardingPage,
    },
    {
        path: '/onboard-welcome',
        name: 'OnBoardWelcomePage',
        component: OnBoardWelcomePage,
    },
    {
        path: '/waitlist',
        name: 'WaitlistPage',
        component: WaitlistPage,
    },
    {
        path: '/membership/membership-introduction',
        name: 'MembershipIntroductionPage',
        component: MembershipIntroductionPage,
    },
    {
        path: '/membership/manager-introduction',
        name: 'ManagerIntroductionPage',
        component: ManagerIntroductionPage,
    },
    {
        path: '/pre-payment/main',
        name: 'PrePaymentPage',
        component: PrePaymentPage,
    },
    // {
    //     path: '/pre-payment/:categoryName',
    //     name: 'PrePaymentCategoryPage',
    //     component: PrePaymentCategoryPage,
    //     props: true,
    // },
    {
        path: '/pre-payment/:categoryName/pay',
        name: 'PaymentMethodsPage',
        component: PaymentMethodsPage,
        props: true,
    },
    {
        path: '/pre-payment/schedule',
        name: 'PaymentCancelFeePage',
        component: PaymentCancelFeePage,
        props: true,
    },
    {
        path: '/pre-payment/:categoryName/pay_ticket',
        name: 'TicketPaymentMethodsPage',
        component: TicketPaymentMethodsPage,
        props: true,
    },
    {
        path: '/pre-payment/:categoryName/pay/account',
        name: 'PayByAccountPage',
        component: PayByAccountPage,
        props: true,
    },
    {
        path: '/pre-payment/account-finish',
        name: 'AccountPayMethodPage',
        component: AccountPayMethodPage,
    },
    {
        path: '/pre-payment/complete-payment',
        name: 'CompletePaymentPage',
        component: CompletePaymentPage,
    },
    {
        path: '/membership/complete-membership-payment',
        name: 'CompleteMembershipPaymentPage',
        component: CompleteMembershipPaymentPage,
    },
    {
        path: '/pre-payment/loading',
        name: 'PaymentLoadingPage',
        component: PaymentLoadingPage,
    },
    {
        path: '/membership/detail',
        name: 'MembershipDetailPage',
        component: MembershipDetailPage,
    },
    {
        path: '/my-page/certificate',
        name: 'CertificatePage',
        component: CertificatePage,
    },
    {
        path: '/',
        name: 'ModalFeedbackEvent',
        component: ModalFeedbackEvent,
    },
    {
        path: '/super-privacy-introduction',
        name: 'SuperPrivacyIntroductionPage',
        component: SuperPrivacyIntroductionPage,
    },
]

const createRouter = () =>
    new Router({
        mode: 'history',
        routes: [...routes, ...signupRoutes],
    })
const router = createRouter()

router.beforeEach((to, from, next) => {
    const found = router.options.routes.some(r => r.name === to.name)
    if (!found) {
        return next('/not-found')
    }

    // ios safearea black
    // const isVerifyPage = to.path.includes('/signup/verify/')
    // if (isVerifyPage) {
    //     Vue.prototype.$nativeBridge.postMessage({
    //         action: 'setBackgroundColor',
    //         value: {
    //             top: '#FFF9EF',
    //             bottom: '#FFF9EF',
    //         },
    //     })
    // } else {
    Vue.prototype.$nativeBridge.postMessage({
        action: 'setBackgroundColor',
        value: {
            top: '#FFF9EF',
            bottom: '#FFF9EF',
        },
    })
    // }

    window.onpopstate = () => {
        // If the dialog is opened,
        // just close the dialog and don't move route
        if ($store.getters.modals.length > 0) {
            $store.commit('popModal')
            return next(false)
        }
        const currentCustomBackHandler = Vue.prototype.$backHandlers[to.name]
        if (currentCustomBackHandler) {
            const allowBack = currentCustomBackHandler()

            // 등록된 핸들러가 True여서 라우트 이동을 해야하는데, 스택라우트면 pop
            if (!!allowBack && $store.getters.pageStack.length > 0) {
                const needActionBeforeDestroy = ['EditProfilePage', 'MakeContractPage']
                const isEditProfilePage = needActionBeforeDestroy.includes(
                    $store.getters.pageStack[$store.getters.pageStack.length - 1].name
                )
                if (isEditProfilePage) {
                    return next(false)
                }
                $store.dispatch('stackRouterPop')
                return next(false)
            }

            return next(!!allowBack)
        }

        // 스택 라우터에 등록되있으면
        if ($store.getters.pageStack.length > 0) {
            $store.dispatch('stackRouterPop')
            return next(false)
        }

        if (Vue.prototype.$isRootRoute()) return next(false)
    }

    next()
})

router.afterEach(() => {
    Vue.prototype.$nativeBridge.postMessage({
        action: 'setCanGoBack',
        value: !Vue.prototype.$isRootRoute(),
    })
})

export default router
