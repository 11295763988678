<template>
    <div
        class="header-bar flex-row"
        :class="{ membership: isMembershipIntro }"
        v-if="$route.name !== 'ModalFeedbackEvent'"
    >
        <div @click="onClickLeftButton" class="left flex-row flex-wrap center">
            <i
                v-if="$route.name !== 'AccountPayMethodPage' && $route.name !== 'CompletePaymentPage'"
                class="back-button material-icons"
                >{{ 'chevron_left' }}
            </i>
        </div>
        <div v-if="isMembershipIntro" class="title f-medium center">
            <img src="@/assets/images/membership-intro/goodperson_text.png" alt="Logo" class="logo" />
        </div>
        <div v-else-if="isManagerIntro" class="title f-medium center" v-html="'매니저 선택'" />
        <div v-else class="title f-medium center" v-html="$translate(hideTitle ? '' : title)" />
        <div class="right flex-wrap" @click="onClickLeftButton">
            <!-- <img
                v-if="$route.name === 'AccountPayMethodPage' || $route.name === 'CompletePaymentPage'"
                class="m-r-2"
                :src="require(`@/assets/images/icons/${backIcon}.png`)"
                width="24px"
                height="24px"
            /> -->
            <img
                v-if="$route.name === 'AccountPayMethodPage'"
                class="m-r-2"
                :src="require(`@/assets/images/icons/${backIcon}.png`)"
                width="24px"
                height="24px"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderBar',
    data: () => ({
        title: 'VANILLABRIDGE',
        customTitle: null,
    }),
    watch: {
        $route() {
            this.initTitle()
        },
    },
    computed: {
        isMembershipIntro() {
            return this.title === 'MEMBERSHIP_INTRODUCTION_PAGE'
        },
        isManagerIntro() {
            return this.title === 'MANAGER_INTRODUCTION_PAGE'
        },
        onboardTempSignup() {
            return this.$route.path.includes('signup/temp/')
        },
        prePayment() {
            if (
                this.$route.name === 'PaymentMethodsPage' ||
                this.$route.name === 'TicketPaymentMethodsPage' ||
                this.$route.name === 'PayByAccountPage' ||
                this.$route.name === 'PaymentCancelFeePage' ||
                this.$route.name === 'InAppStorePage'
            )
                return true
            return this.$route.path.includes('pre-payment/')
        },
        backIcon() {
            console.log(this.$route.name)
            if (
                [
                    'PreferencesIntroPage',
                    'NPSMainPage',
                    'NTSMainPage',
                    'IntroductionPage',
                    'Refund1Page',
                    'AccountPayMethodPage',
                    'CompletePaymentPage',
                ].indexOf(this.$route.name) > -1
            )
                return 'close'

            return 'back'
            // return 'keyboard_backspace'
        },
        hideTitle() {
            if (this.onboardTempSignup || this.prePayment) return false

            return ['ChatroomPage'].indexOf(this.$route.name) === -1
        },
    },
    methods: {
        initTitle() {
            if (this.onboardTempSignup) {
                if (this.$route.path.includes('state')) {
                    this.title = '거주 지역'
                } else {
                    this.title = '서비스 방향성 동의'
                }
                return
            }
            console.log('initTitle', this.customTitle, this.$route.name)
            console.log('initTitle2', this.$case.toSnake(this.$route.name).toUpperCase())
            this.title = this.customTitle || this.$case.toSnake(this.$route.name).toUpperCase()
            this.customTitle = ''
            this.$nativeBridge.postMessage({
                action: 'setBackgroundColor',
                value: {
                    top: '#FAF0E1',
                    bottom: '#FFF9EF',
                },
            })
        },
        onClickLeftButton() {
            // 여기에 직접 로직이 들어가는게 좀 그렇긴 하지만 다른 방법이...?
            if (this.$route.name === 'PreferencesIntroPage') {
                this.$router.push({ name: 'HomePage' })
                return
            }

            if (this.$route.name === 'AccountPayMethodPage' || this.$route.name === 'CompletePaymentPage') {
                this.$router.go(-2)
                return
            }

            this.$router.go(-1)
        },
    },
    mounted() {
        this.initTitle()
    },
    // beforeDestroy() {
    //     this.$nativeBridge.postMessage({
    //         action: 'setBackgroundColor',
    //         value: '#FFFFFF',
    //     })
    // },
}
</script>

<style lang="scss" scoped>
.header-bar {
    height: 56px;
    padding-left: $header-left-padding;
    padding-right: $header-left-padding;
    background: #faf0e1;

    &.membership {
        background: #181e37;
    }

    &.bordered {
        border-bottom: 1px solid $grey-02;
    }

    &.dark {
        &.bordered {
            border-bottom: 1px solid $grey-07;
        }
        background-color: $grey-09;
    }

    .back-button {
        left: 8px;
        color: $grey-08;
    }

    .left,
    .right {
        //width: 60px;
        transition: none;
    }

    .title {
        color: black;
        font-size: 16px;

        &.white {
            color: white;
        }

        ::v-deep .sub {
            border-radius: 6px;
            border: solid 1px $brand-recommended;
            font-size: 11px;
            padding: 2px 6px;
            display: flex;
            align-items: center;
        }

        ::v-deep .material-icons {
            margin-left: 4px;
            font-size: 14px;
            color: $blue-suggest;
        }
    }

    .left {
        width: 24px;
        justify-content: flex-start;
    }

    .right {
        display: flex;
        width: 24px;
        justify-content: flex-end;
        align-items: center;
    }

    i {
        font-size: 24px;
        color: $grey-08;
        &.white {
            color: white;
        }
    }

    .logo {
        width: 115px;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
</style>
