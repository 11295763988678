var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"manager-introduction"},[_vm._m(0),_c('div',{staticClass:"manager-section professional"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('div',{staticClass:"cta-box"},[_vm._m(9),_c('button',{staticClass:"cta-btn professional f-14",on:{"click":function($event){return _vm.upgradeManger()}}},[_vm._v("업그레이드 하기")])])]),_c('div',{staticClass:"cs-center-btn",staticStyle:{"z-index":"10","right":"5%","bottom":"5%","position":"absolute"}},[_c('img',{staticClass:"like",attrs:{"src":_vm.bubbleChatIcon,"alt":"chat-icon","width":"25px","height":"25px"},on:{"click":function($event){return _vm.openCScenter()}}}),_c('div',{staticClass:"cs-text"},[_vm._v("문의하기")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"manager-section basic"},[_c('div',{staticClass:"title-box"},[_c('p',{staticClass:"title-text"},[_vm._v("베이직 메니저")]),_c('p',{staticClass:"title-desc"},[_vm._v("월 1~2회 이상 문제 없이 매칭되고 있는 분에게 추천")])]),_c('div',{staticClass:"flex-box"},[_c('i',{staticClass:"material-icons"},[_vm._v("check_circle")]),_c('span',[_vm._v("AI 추천 기반 프로필 제공")])]),_c('div',{staticClass:"flex-box"},[_c('i',{staticClass:"material-icons"},[_vm._v("check_circle")]),_c('span',[_vm._v("평가가 누적될수록 정확도 향상")])]),_c('div',{staticClass:"cta-box"},[_c('div',{staticClass:"cta-header"},[_c('p',{staticClass:"cta-title"},[_vm._v("무료")])]),_c('button',{staticClass:"cta-btn basic f-14"},[_vm._v("현재 이용중")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-box"},[_c('p',{staticClass:"title-text"},[_vm._v("프로페셔널 메니저")]),_c('p',{staticClass:"title-desc"},[_vm._v("프로필이 마음에 들지 않거나, 원하는 만큼 매칭이 안 되는 분에게 추천")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-box"},[_c('i',{staticClass:"material-icons"},[_vm._v("check_circle")]),_c('span',[_vm._v("전문 매니저 1:1 관리")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-box"},[_c('i',{staticClass:"material-icons"},[_vm._v("check_circle")]),_c('span',[_vm._v("쌍방향 소통하며 매칭 진행")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-box"},[_c('i',{staticClass:"material-icons"},[_vm._v("check_circle")]),_c('span',[_vm._v("디테일한 선호 사항 반영")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-box"},[_c('i',{staticClass:"material-icons"},[_vm._v("check_circle")]),_c('span',[_vm._v("수락/거절 반영한 프로필 추천")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-box"},[_c('i',{staticClass:"material-icons"},[_vm._v("check_circle")]),_c('span',[_vm._v("프로필 피드백 제공")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-box"},[_c('i',{staticClass:"material-icons"},[_vm._v("check_circle")]),_c('span',[_vm._v("상세 프로필 조회 기능 무료 제공")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-box"},[_c('i',{staticClass:"material-icons"},[_vm._v("check_circle")]),_c('span',[_vm._v("월 평균 2.7회 매칭")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cta-header"},[_c('span',{staticClass:"cta-title"},[_vm._v(" 2.9 ")]),_c('span',{staticClass:"cta-title-sub"},[_vm._v("만원/월")]),_c('p',{staticClass:"cta-text"},[_vm._v("*부가세 별도")])])
}]

export { render, staticRenderFns }