<template>
    <div class="complete-subs-payment">
        <div class="header">
            <div class="close-icon" @click="onClickClose()">
                <i class="material-icons i-right f-24" @click="onClickClose()">close</i>
            </div>
        </div>
        <main v-if="this.product.code !== 'pro_manager'" class="main">
            <div class="main-title">
                <div class="f-24 f-bold m-t-20">
                    {{ $translate(product.code.toUpperCase()) }} 멤버십이 <br />
                    시작되었습니다!
                </div>
            </div>
            <div class="add-info m-b-40 m-t-40">
                <div class="title f-16 f-bold">1. 지금부터 모든 프로필 열람이 가능해요!</div>
                <div class="content">
                    <div class="item">
                        <div class="key dotted">*</div>
                        <div class="f-14">세부 지역, 경제력, 학력 등 모든 프로필이 열람 가능합니다.</div>
                    </div>
                </div>
            </div>
            <div class="add-info m-b-40">
                <div class="title f-16 f-bold">2. Marry Fit 멤버십 회원 전용 매칭 선호사항을 입력해주세요.</div>
                <div class="content">
                    <div class="item">
                        <div class="key dotted">*</div>
                        <div class="f-14">
                            최대 7가지 필터와 우선순위 설정이 가능해요. ( ‘마이페이지’와 매니저 대화방에서 입력 가능)
                        </div>
                    </div>
                </div>
            </div>
            <div class="add-info m-b-40">
                <div class="title f-16 f-bold">3. 곧 새로운 매니저를 배정해드릴 예정입니다.</div>
                <div class="content">
                    <div class="item">
                        <div class="key dotted">*</div>
                        <div class="f-14">회원님을 위한 전문 매니저를 새롭게 배정해드릴 예정이에요!</div>
                    </div>
                    <div class="item">
                        <div class="key dotted">*</div>
                        <div class="f-14">
                            기존 회원님의 매칭 기록은 모두 새로운 매니저에게 인수인계 되오니 걱정하지 않으셔도 좋습니다.
                        </div>
                    </div>
                    <div class="item">
                        <div class="key dotted">*</div>
                        <div class="f-14">매니저 배정에는 1~2 영업일이 소요됩니다.</div>
                    </div>
                </div>
            </div>
        </main>

        <main v-else class="main">
            <div class="main-title">
                <div class="f-24 f-bold m-t-20">
                    프로 매니저를 <br />
                    구독해주셔서 감사해요!
                </div>
            </div>
            <div class="add-info m-b-40 m-t-40">
                <div class="title f-16 f-bold">1. 지금부터 모든 프로필 열람이 가능해요!</div>
                <div class="content">
                    <div class="item">
                        <div class="key dotted">*</div>
                        <div class="f-14">세부 지역, 경제력, 학력 등 모든 프로필이 열람 가능합니다.</div>
                    </div>
                </div>
            </div>
            <div class="add-info m-b-40">
                <div class="title f-16 f-bold">2. 매칭 선호사항을 입력해주세요.</div>
                <div class="content">
                    <div class="item">
                        <div class="key dotted">*</div>
                        <div class="f-14">
                            매니저가 회원님이 설정한 내용을 참고해 매칭을 진행합니다. ('마이페이지 > 매칭 선호사항'에서
                            설정 가능)
                        </div>
                    </div>
                </div>
            </div>
            <div class="add-info m-b-40">
                <div class="title f-16 f-bold">3. 곧 새로운 매니저를 배정해드릴 예정입니다.</div>
                <div class="content">
                    <div class="item">
                        <div class="key dotted">*</div>
                        <div class="f-14">회원님의 니즈에 맞는 매니저를 새롭게 배정해드릴 예정이에요!</div>
                    </div>
                    <div class="item">
                        <div class="key dotted">*</div>
                        <div class="f-14">지난 매칭 기록은 새 매니저에게 인수인계 됩니다.</div>
                    </div>
                    <div class="item">
                        <div class="key dotted">*</div>
                        <div class="f-14">매니저 배정에는 1~2영업일이 소요됩니다.</div>
                    </div>
                    <div class="item">
                        <div class="key dotted">*</div>
                        <div class="f-14">프로 매니저 구독 기간은 매니저 배정일부터 시작됩니다.</div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
export default {
    name: 'CompleteMembershipPaymentPage',
    computed: {
        order() {
            return JSON.parse(localStorage.getItem('order'))
        },
        product() {
            const products = this.$store.getters.products.all

            if (this.order) {
                return products.find(p => p.id === this.order.product_id)
            } else {
                return products[14]
            }
        },
    },
    methods: {
        onClickClose() {
            this.$router.push({ name: 'ChatsPage' })
        },
    },
    mounted() {},
}
</script>

<style scoped lang="scss">
.complete-subs-payment {
    .photo-container {
        width: 100%;

        img {
            width: 100%;
            height: auto;
        }
    }
    .main {
        padding: 0 20px 20px 20px;
        overflow-y: auto;
    }
    h2 {
        font-size: 24px;
        line-height: 36px;
        margin: 16px 0 40px 0;
    }
    .main-title {
        color: black;
        line-height: 36px;
        text-align: start;
    }
    .title {
        color: #03006e;
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 8px;
    }
    .item {
        display: flex;
        font-size: 14px;
        margin-bottom: 6px;
        line-height: 24px;

        .key {
            width: 72px;
            color: $grey-06;

            &.dotted {
                width: fit-content;
                margin-right: 4px;
                color: #111111;
            }
        }
        .value {
            font-size: 16px;
            line-height: 26px;
        }
    }
    .header {
        height: 57px;
        padding: 16px 16px 32px 16px;
        background: #faf0e1;

        .close-icon {
            position: absolute;
            top: 16px;
            right: 20px;
        }
        .text-center {
            .img-container {
                @include center;
            }
            .h3 {
                text-align: center;
                font-size: 18px;
                margin: 11px 0 24px 0;
                @include spoqa-f-bold;
            }
        }
    }
    .grey-box {
        background: #f8eee4;
        padding: 12px;
        font-size: 16px;
        line-height: 24px;
        border-radius: 12px;
        display: flex;
        align-items: center;

        .img-container {
            width: 64px;
            color: $grey-04;
            margin-right: 8px;
            display: flex;
            align-items: center;

            img {
                width: 100%;
                height: auto;
            }
        }
    }
    .purchase-btn {
        bottom: 0px;
        width: calc(100% - 40px);
        // margin: 16px 0;
        // padding: 0 4px;
        // height: 48px;
        position: fixed;
        .bottom-btn {
            width: 100%;
            // height: 100%;
            border: none;
            background: #151360;
            border-radius: 36px;
            color: #f7f7f4;
        }
    }
}
</style>
